import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../../../assets/images/advice.header.ecowatt.jpg'


const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "HeaderMainPage__breadcrumb-link-text" }
const _hoisted_3 = {
  key: 0,
  class: "advice__container"
}
const _hoisted_4 = { class: "inner advice__inner" }
const _hoisted_5 = { class: "adviceHeader__title" }
const _hoisted_6 = { class: "inner advice__inner" }
const _hoisted_7 = { class: "advice__text1" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "advices__container"
}
const _hoisted_11 = { class: "inner advice__inner" }
const _hoisted_12 = { class: "advice__header" }
const _hoisted_13 = { class: "advice__header__title" }

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import logo from "@/assets/images/logo_beta.svg?url";
import ERoutes from "@/router/routes";
import API from "@/helpers/api";
import {TAdvice} from "./interface";
import {computed, onBeforeMount, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdviceSingleView',
  setup(__props) {

const advice = ref<TAdvice | null>(null);

const {t} = useI18n()
const route = useRoute()
const router = useRouter()

const adviceTitle = computed(() => {
  return t(route.params.advice as string || '');
})

const isEcowatt = computed(() => {
  return route.params.advice.includes("ecowatt");
})

const getAdviceImage = (advice: string) => {
  const images = require.context('../../../assets/images', false);
  return images('./' + advice + '.jpg');
}

onBeforeMount(() => {
  window.scrollTo(0, 0);
  const adviceValue = route.params.advice;
  if (isEcowatt.value) return;
  if (!adviceValue) {
    router.push(ERoutes.ADVICES);
  }

  API.get(`/api/user/advice/${adviceValue}`).then(({data}) => {
    advice.value = data;
  })
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_nl2br = _resolveDirective("nl2br")!

  return (_openBlock(), _createBlock(MyVPageMain, {
    homeLink: _unref(ERoutes).HOME,
    logo: _unref(logo),
    title: _ctx.$t('app.title'),
    hasProgramBar: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVHeaderMainPage, { title: adviceTitle.value }, {
          HeaderMainPage__breadcrumb: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: _unref(ERoutes).ADVICES,
              class: "HeaderMainPage__breadcrumb-link"
            }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "HeaderMainPage__breadcrumb-link-icon" }, [
                  _createElementVNode("svg", {
                    class: "icon icon-arrow-left",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_2, " < " + _toDisplayString(_ctx.$t("advice.breadcrumb.backLink")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      (advice.value && !isEcowatt.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "adviceHeader",
                style: _normalizeStyle(`background-image:url(${getAdviceImage(advice.value.category)});`)
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t(advice.value.title)), 1)
              ], 4)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", {
                  innerHTML: _ctx.$t(advice.value.introduction)
                }, null, 8, _hoisted_8)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(advice.value.keys, (key, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  (_ctx.$t(key).length > 0)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(index % 2 === 0 ? 'advice__blockText advice__blockText--1': 'advice__blockText advice__blockText--2')
                      }, [
                        _withDirectives(_createElementVNode("p", {
                          class: "advice__blockText-text",
                          innerHTML: _ctx.$t(key)
                        }, null, 8, _hoisted_9), [
                          [_directive_nl2br]
                        ])
                      ], 2))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
        : (isEcowatt.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('advice.ecowatt.title')), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("iframe", {
                  allowTransparency: "true",
                  width: "100%",
                  src: "https://www.monecowatt.fr?preview=true"
                }, null, -1))
              ])
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["homeLink", "logo", "title"]))
}
}

})