import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "quick-settings" }
const _hoisted_2 = { class: "quick-settings-name" }

import {HeatingModule} from "@/store";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {computed} from "vue";
import {EQuickSettingsName, TQuickSettings} from "@/services/Heating/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVQuickSettings',
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const quickSettingsSelect = (quickSettings: TQuickSettings) => {
  emit('update:value', quickSettings)
}

const iconQuickSetting = computed(() => {
  return (quickSettingName: string) => {
    switch (quickSettingName) {
      case EQuickSettingsName.AtHome:
      default:
        return 'inside';
      case EQuickSettingsName.ShortAbsence:
        return 'outside';
      case EQuickSettingsName.LongAbsence:
        return 'work';
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(HeatingModule).quickSettings, (quickSetting) => {
      return (_openBlock(), _createBlock(MyVButton, {
        key: quickSetting.id,
        onClick: ($event: any) => (quickSettingsSelect(quickSetting))
      }, {
        default: _withCtx(() => [
          _createVNode(MyVIcon, {
            name: iconQuickSetting.value(quickSetting.name)
          }, null, 8, ["name"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(`${quickSetting.name}.short`)), 1)
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 128))
  ]))
}
}

})