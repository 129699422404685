import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "monthly-consumption-graph" }
const _hoisted_2 = { class: "monthly-consumption-graph-content" }
const _hoisted_3 = { class: "monthly-consumption-graph-content-block monthly-consumption-graph-content-total" }
const _hoisted_4 = { class: "monthly-consumption-graph-content-total-title" }
const _hoisted_5 = { class: "monthly-consumption-graph-content-total-subtitle" }
const _hoisted_6 = { class: "monthly-consumption-graph-content-block monthly-consumption-graph-content-infos" }
const _hoisted_7 = {
  key: 0,
  class: "monthly-consumption-graph-content-infos-item offPeak"
}
const _hoisted_8 = { class: "monthly-consumption-graph-content-infos-item peak" }
const _hoisted_9 = { class: "monthly-consumption-graph-content-infos-item" }
const _hoisted_10 = { class: "monthly-consumption-graph-content-block monthly-consumption-graph-content-details" }
const _hoisted_11 = { class: "monthly-consumption-graph-content-details-item" }

import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import {Divider} from 'ant-design-vue';
import {computed, ref} from "vue";
import {TMonthlyConsumptionType} from "@/components/domains/Conso/MyVMonthlyConsumptionGraph/interfaces";
import {ContractModule, SwitchConsoModule} from "@/store";
import {EEnergyUnit} from "@/helpers/constants/unit";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {useRouter} from "vue-router";
import ERoutes from "@/router/routes";
import Historic from "@/components/domains/Conso/MyVHistoric/MyVHistoric.vue";
import {useResponsive} from "@/composables/useResponsive";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import useCurrency from "@/composables/currency";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVMonthlyConsumptionGraph',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props

const showHistoric = ref(false);
const {isMobile} = useResponsive()
const {formatCurrency} = useCurrency()

const dataSet = computed(() => {
  const total = Math.max(props.data.total, props.data.estimated);

  const peak = {value: (props.data.peakHour / total) * 100, color: '#4DBEF7FF'};
  const offPeak = {value: (props.data.offPeakHour / total) * 100, color: '#97BE0DFF'};
  const estimated = {value: 100 - (peak.value + offPeak.value), color: '#E4E8EF'};

  return [estimated, offPeak, peak].filter(item => item.value > 0);
});

const chartDatas = computed(() => {
      return {
        type: "doughnut",
        data: {
          datasets: [{
            data: dataSet.value.map(item => item.value),
            backgroundColor: dataSet.value.map(item => item.color),
            borderWidth: 0,
          }]
        },
        options: {
          animation: {
            animateScale: true
          },
          responsive: true,
          rotation: 219.5,
          circumference: 280,
          cutout: '92%',
          hover: {mode: null},
          layout: {
            padding: isMobile.value ? 0 : 15
          },
          plugins: {
            datalabels: {
              display: false,
            },
            tooltip: {
              enabled: false
            },
            legend: {
              display: false
            },
          }
        }
      }
    }
)
const router = useRouter()

const consoType = computed<boolean>(() => {
  return SwitchConsoModule.switchType;
})

function formatConsoValue(value: number) {
  const valueRounded: number = Math.round(value ?? 0);

  if (consoType.value) return `${valueRounded} ${EEnergyUnit.kWh}`

  return formatCurrency(valueRounded)
}

const toggleShowHistoric = () => {
  if (showHistoric.value) {
    showHistoric.value = false
    pageScroll(true);
  } else {
    pageScroll(false);
    showHistoric.value = true
  }
}

const handleClick = () => {
  router.push({
    name: ERoutes.CONSO_MONTH,
    path: ERoutes.CONSO, query: {type: 'month'},
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (chartDatas.value)
        ? (_openBlock(), _createBlock(_unref(Vue3ChartJs), _mergeProps({
            key: 0,
            ref: "chartRef"
          }, chartDatas.value), null, 16))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(formatConsoValue(_ctx.data.total)), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(consoType.value ? _ctx.$t('monthlyConsumptionGraph.total.consumed') : _ctx.$t('monthlyConsumptionGraph.total.estimated')), 1)
        ]),
        _createVNode(_unref(Divider)),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.data.offPeakHour > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('welcome.chart.off_peak_hour')), 1),
                _createElementVNode("div", null, _toDisplayString(formatConsoValue(_ctx.data.offPeakHour)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, _toDisplayString(_unref(ContractModule).currentContract?.isPeakOffPeakContract || _ctx.data.offPeakHour > 0 ? _ctx.$t('welcome.chart.peak_hour') : _ctx.$t('conso.chart.consumption')), 1),
            _createElementVNode("div", null, _toDisplayString(formatConsoValue(_ctx.data.peakHour)), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('monthlyConsumptionGraph.infos.forecast')), 1),
            _createElementVNode("div", null, _toDisplayString(formatConsoValue(_ctx.data.estimated)), 1)
          ])
        ]),
        _createVNode(_unref(Divider)),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(MyVButton, {
              onClick: handleClick,
              round: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('monthlyConsumptionGraph.details.viewDetails')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createElementVNode("a", { onClick: toggleShowHistoric }, _toDisplayString(_ctx.$t('monthlyConsumptionGraph.details.viewHistory')), 1)
            ])
          ])
        ])
      ])
    ]),
    (showHistoric.value)
      ? (_openBlock(), _createBlock(Historic, {
          key: 0,
          onClose: toggleShowHistoric
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})