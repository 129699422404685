import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "programCreate__init PageWithImg__inner"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "programCreate__button-text" }
const _hoisted_4 = {
  key: 1,
  class: "programCreate__tunnel"
}

import {computed, onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import {AuthModule, ProgramModule} from "@/store";

import router from "@/router";
import ERoutes from "@/router/routes";

import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import MyVAntdinput from '@/components/ui/atoms/MyVAntdinput/MyVAntdinput.vue';
import MyVPageWithImg from "@/components/ui/organisms/layouts/MyVPageWithImg/MyVPageWithImg.vue";

import {EProgramCreateMethod, TProgramCreateBase} from './interfaces';
import ProgramFromHabit from './ProgramFromHabitView.vue';
import ProgramDuplicate from './ProgramDuplicateView.vue';
import ProgramCreateSuccessView from "@/views/domains/Heating/Program/ProgramCreate/ProgramCreateSuccessView.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";

import backgroundImg from "@/assets/images/bg-create-program.jpg";
import backgroundImgMobile from "@/assets/images/bg-create-program-mobile.jpg";
import {TProgram} from "@/services/Program/interfaces";
import {EVoltalisVersion} from "@/services/Appliances/interfaces";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {checkForAlphanumericAndAccent} from "@/helpers/string/inputFormat";
import {DISPLAY_GROUP_PRO} from "@/services/Theme/interfaces";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramCreateView',
  setup(__props) {

const origin = ref<string | null>('');
const showPopinWarning = ref(false)
const nameError = ref('');
const input = ref(null);
const creationMethod = ref<EProgramCreateMethod | null>(null);
const form = reactive<TProgramCreateBase>({
  name: '',
});
const {t} = useI18n()

const editProgramLink = computed(() => {
  return (id: TProgram['id']) => `${ERoutes.PROGRAM}/${id}/${ERoutes.ADDON_PLANNING}`;
})

const selectedMethodForm = computed(() => {
  switch (creationMethod.value) {
    case EProgramCreateMethod.Habit:
      return ProgramFromHabit;
    case EProgramCreateMethod.Duplicate:
      return ProgramDuplicate;
  }
  return undefined;
})

const isVxVersion = computed(() => {
  return AuthModule.user.defaultSite.voltalisVersion === EVoltalisVersion.Vx
})

const closePopinWarning = () => {
  showPopinWarning.value = false
}

const isNameValid = () => {
  if (form.name.length === 0)
    nameError.value = "programCreate.form.error.missing_name";
  else if (ProgramModule.programs?.find(program => program.name?.toLowerCase() === form.name!.toLowerCase()))
    nameError.value = "programCreate.form.error.already_existing_name";
  else
    return true;
  return false;
}

const startCreate = (actionCreate: EProgramCreateMethod) => {
  if (isNameValid()) {
    switch (actionCreate) {
      case EProgramCreateMethod.Habit:
      case EProgramCreateMethod.Duplicate:
        creationMethod.value = actionCreate;
        break;
      case EProgramCreateMethod.Zero:
        ProgramModule.createOrDuplicateProgram({
          name: form.name
        })
            .then(program => {
              router.push(editProgramLink.value(program.id));
            });
        break;
    }
  }
}

const cancelCreate = () => {
  creationMethod.value = null;
}

const checkAlphaNumericAndAccent = (event: any) => {
  checkForAlphanumericAndAccent(event);
}

const generateUniqueName = (count = 0): string => {
  const baseName = t('programCreate.defaultValue.program_name');
  const newName = count === 0 ? baseName : `${baseName} ${count}`;
  const existingNames = ProgramModule.programs?.map(program => program.name) ?? [];

  if (existingNames.includes(newName)) {
    return generateUniqueName(count + 1);
  }

  return newName;
}

onMounted(() => {
  const input = document.querySelectorAll('input[type=text]')[0]
  if (input)
    (input as HTMLElement).focus()
})

onBeforeMount(async () => {
  await ProgramModule.getPrograms(true);

  form.name = generateUniqueName()
  origin.value = new URLSearchParams(window.location.search).get("origin");
})

watch(() => form.name, (newValue, oldValue) => {
  if (nameError.value.length > 0 && oldValue !== newValue) {
    nameError.value = '';
  }
})

watch(creationMethod, (newValue) => {
  pageScroll(!newValue);
})

watch(showPopinWarning, (newValue) => {
  pageScroll(newValue);
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MyVPageWithImg, {
      logo: "",
      "logo-dark": "",
      title: _ctx.$t('programCreate.title'),
      subtitle: _ctx.$t('programCreate.subtitle'),
      backgroundImg: _unref(backgroundImg),
      backgroundImgMobile: _unref(backgroundImgMobile)
    }, {
      back: _withCtx(() => [
        _createVNode(_component_router_link, {
          to:  _unref(ERoutes).PROGRAM,
          class: "PageWithImg__back-close"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("svg", {
              class: "icon icon-cross",
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", { "xlink:href": "#icon-cross" })
            ], -1)
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      default: _withCtx(() => [
        (selectedMethodForm.value === undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(MyVAntdinput, {
                ref_key: "input",
                ref: input,
                onKeydown: checkAlphaNumericAndAccent,
                label: _ctx.$t('programCreate.label.program_name'),
                inputType: _unref(EInputType).Text,
                value: form.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.name) = $event)),
                id: "programName"
              }, _createSlots({ _: 2 }, [
                (nameError.value)
                  ? {
                      name: "help",
                      fn: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "nameError",
                          innerHTML: _ctx.$t(nameError.value)
                        }, null, 8, _hoisted_2)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["label", "inputType", "value"]),
              (_unref(AuthModule).user.displayGroup.name !== _unref(DISPLAY_GROUP_PRO))
                ? (_openBlock(), _createBlock(MyVButton, {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (startCreate(_unref(EProgramCreateMethod).Habit))),
                    type: "button",
                    primary: false,
                    icon: "carret-right",
                    class: "programCreate__button"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('programCreate.create_from_habit')) + " ", 1),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('programCreate.create_from_habit_infos')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (origin.value!='onboarding')
                ? (_openBlock(), _createBlock(MyVButton, {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (startCreate(_unref(EProgramCreateMethod).Duplicate))),
                    type: "button",
                    primary: false,
                    class: "programCreate__button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('programCreate.duplicate_from_existing')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (origin.value!='onboarding')
                ? (_openBlock(), _createBlock(MyVButton, {
                    key: 2,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (showPopinWarning.value = isNameValid())),
                    type: "button",
                    primary: false,
                    class: "programCreate__button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('programCreate.create_from_scratch')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(selectedMethodForm.value), {
                cancelCreate: cancelCreate,
                baseForm: form,
                generateEditLink: editProgramLink.value
              }, null, 8, ["baseForm", "generateEditLink"]))
            ]))
      ]),
      _: 1
    }, 8, ["title", "subtitle", "backgroundImg", "backgroundImgMobile"]),
    (showPopinWarning.value)
      ? (_openBlock(), _createBlock(MyVPageWithReturn, {
          key: 0,
          title: "",
          type: "popin",
          "return-action": closePopinWarning
        }, {
          default: _withCtx(() => [
            _createVNode(ProgramCreateSuccessView, {
              isStartingFromZero: true,
              onDiscover: _cache[4] || (_cache[4] = ($event: any) => (startCreate(_unref(EProgramCreateMethod).Zero))),
              isVx: isVxVersion.value
            }, null, 8, ["isVx"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})