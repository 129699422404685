import {createStore} from "vuex";
import {config, getModule} from "vuex-module-decorators";

// Modules
import Router from './modules/Router';
import Auth from "./modules/Auth";
import Consent from "./modules/Consent";
import Intl from "./modules/Intl";
import Heating from './modules/Heating';
import Program from './modules/Program';
import Appliances from './modules/Appliances';
import Site from './modules/Site';
import Conso from "@/store/modules/Conso";
import Housing from "@/store/modules/Housing";
import Contract from "@/store/modules/Contract";
import Objective from "@/store/modules/Objective";
import SwitchConso from "@/store/modules/SwitchConso";
import Geolocation from "@/store/modules/Geolocation";
import Theme from "./modules/Theme";
import RequestLoader from './modules/RequestLoader';
import IdApplication from "@/store/modules/IdApplication";
import Notification from "@/store/modules/Notification";
import Diagnostic from "@/store/modules/Diagnostic";

import {EApplianceProgType, TAppliances} from "@/services/Appliances/interfaces";
import {EModules} from "./modules";
import timer from "@/helpers/dates/timer";
import {TProgramCacheItem, TProgramWithPlanning} from "@/services/Program/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {EDiagnosticStatus} from "@/services/Diagnostic/interfaces";
import {DateUtils, useDate} from "@/helpers/dates/date-utils";

config.rawError = true;

const store = createStore({
    modules: {
        Router,
        Auth,
        Consent,
        Intl,
        Heating,
        Program,
        Appliances,
        Conso,
        Housing,
        Contract,
        Site,
        Geolocation,
        Objective,
        SwitchConso,
        Theme,
        RequestLoader,
        IdApplication,
        Notification,
        Diagnostic,
    },
});

const defaultState: any = JSON.stringify(store.state);
const noResetStore = [EModules.INTL, EModules.THEME, EModules.SWITCH_CONSO, EModules.ID_APPLICATION];
const notStoreCache = [EModules.AUTH, ...noResetStore];

const _resetStore = (exceptions: EModules[], resetApp = false) => {
    const state = JSON.parse(defaultState);

    for (const module in state) {
        if (exceptions.includes(module as EModules)) {
            state[module] = store.state[module];
        }
    }
    if (resetApp) {
        state[EModules.ID_APPLICATION].key++;
    }

    store.replaceState(state);
};

// Reset everything except translations. Use when logout to clear user's informations.
export const resetStore = () => _resetStore(noResetStore);

// Reset All module use as cache. Those are module that became invalid if some user's datas change.
export const clearStoreCache = (resetApp = false) => _resetStore(notStoreCache, resetApp);

export default store;

export const RouterModule = getModule(Router, store);

export const AuthModule = getModule(Auth, store);

export const ConsentModule = getModule(Consent, store);

export const IntlModule = getModule(Intl, store);

export const HeatingModule = getModule(Heating, store);

export const ProgramModule = getModule(Program, store);

export const AppliancesModule = getModule(Appliances, store);

export const ConsoModule = getModule(Conso, store);

export const HousingModule = getModule(Housing, store);

export const ContractModule = getModule(Contract, store);

export const SiteModule = getModule(Site, store);

export const ObjectiveModule = getModule(Objective, store);

export const SwitchConsoModule = getModule(SwitchConso, store);

export const ThemeModule = getModule(Theme, store);

export const RequestLoaderModule = getModule(RequestLoader, store);

export const IdApplicationModule = getModule(IdApplication, store);

export const NotificationModule = getModule(Notification, store);

export const DiagnosticModule = getModule(Diagnostic, store);

export const GeolocationModule = getModule(Geolocation, store);

store.watch(() => AppliancesModule.appliances, (appliances?: TAppliances) => {
    if (appliances && appliances.length > 0) {
        let smallerDate: DateUtils | undefined = undefined;
        let typeSmaller: EApplianceProgType | undefined = undefined;
        const delay = 10;
        const now = useDate().add(delay, 'seconds');

        for (const {programming: {untilFurtherNotice, endDate, progType}} of appliances) {
            if (untilFurtherNotice === false && endDate !== null) {
                const parsedEndDate = useDate(endDate, 'YYYY-MM-DDTHH:mm:ss').add(delay, 'seconds');

                if (parsedEndDate > now && (smallerDate === undefined || smallerDate > parsedEndDate)) {
                    smallerDate = parsedEndDate;
                    typeSmaller = progType;
                }
            }
        }

        if (smallerDate) {
            timer(smallerDate, () => {
                switch (typeSmaller) {
                    case EApplianceProgType.Manual:
                        HeatingModule.getManualSettings(true);
                        break;
                    case EApplianceProgType.Quick:
                        HeatingModule.getQuickSettings(true);
                        break;
                }

                AppliancesModule.getAppliances(true);
                ProgramModule.getProgrammingState();
            });
        }
    }
})

store.watch(() => ProgramModule.programs, (programs?) => {

    let smallerDate: DateUtils | undefined = undefined;
    const delay = 10;
    const now = useDate().add(delay, 'seconds');

    const prog: TProgramCacheItem | undefined = programs?.find((({enabled}) => enabled));
    const progWithPlannings = prog as TProgramWithPlanning ?? null;

    if (progWithPlannings && progWithPlannings!.plannings) {
        const plannings = progWithPlannings.plannings;

        plannings.forEach(planning => {
            planning.dailyprogs.forEach(dailyprog => {
                if (dailyprog.dayNumber === now.day()) {
                    dailyprog.ranges.forEach(range => {
                        const endDate = useDate(`2000-01-01T${range.from}`, 'HH:mm').add(delay, 'seconds');
                        if (endDate > now && (smallerDate === undefined || smallerDate > endDate)) {
                            smallerDate = endDate;
                        }
                    })
                }
            })
        })

        if (smallerDate) {
            timer(smallerDate, () => {
                HeatingModule.getManualSettings(true);
                AppliancesModule.getAppliances(true);
                ProgramModule.getProgrammingState();
            })
        }
    }
});

store.watch(() => NotificationModule.notifications, () => {
    const delay = 1;
    const now = useDate().add(delay, 'minutes');
    const user = AuthModule.user;
    timer(now, () => {
        NotificationModule.getNotifications({
            siteId: getCurrentSiteIdFromCookie(user),
            forceRequest: true,
            skipLoading: true,
        })
    })
});

store.watch(() => DiagnosticModule.diagnostics, async () => {
    const delay = 30000;
    const maxDelay = 300000;
    const user = AuthModule.user;
    const getDiagDatas = () => {
        DiagnosticModule.getDiagnostics({
            siteId: getCurrentSiteIdFromCookie(user),
            forceRequest: true,
            skipLoading: true
        });
    };

    if (DiagnosticModule.diagnostics?.some(diag => diag.status === EDiagnosticStatus.TEST_IN_PROGRESS)) {
        clearInterval(DiagnosticModule.pollId)
        DiagnosticModule._setPollId(setInterval(getDiagDatas, delay) as unknown as number)
        setTimeout(() => {
            clearInterval(DiagnosticModule.pollId)
        }, maxDelay) //stop polling after 5min
    } else {
        clearInterval(DiagnosticModule.pollId)
    }
})