import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "WelcomeContainer" }
const _hoisted_2 = {
  class: "heating-section",
  "data-testid": "welcome-heating-section"
}
const _hoisted_3 = {
  key: 0,
  class: "heating-section-quick-access"
}
const _hoisted_4 = { class: "heating-section-quick-access-block" }
const _hoisted_5 = { class: "heating-section-quick-access-block" }
const _hoisted_6 = { class: "heating-section-quick-access-block-buttons" }
const _hoisted_7 = {
  key: 1,
  class: "heating-section-quick-access"
}
const _hoisted_8 = { class: "heating-section-quick-access-block__full" }
const _hoisted_9 = { class: "heating-section-quick-modes" }
const _hoisted_10 = { class: "welcome-desktop-list WelcomeDesktop__show" }
const _hoisted_11 = { class: "welcome-desktop-list-programs" }
const _hoisted_12 = { class: "heating-section-quick-access-block" }
const _hoisted_13 = { class: "heating-section-quick-access-block-title" }
const _hoisted_14 = { class: "heating-section-quick-access-block" }
const _hoisted_15 = {
  key: 0,
  class: "heating-section-quick-access-block-title"
}
const _hoisted_16 = {
  key: 1,
  class: "heating-section-quick-access-block-title"
}
const _hoisted_17 = { class: "heating-section-quick-access-block-buttons" }
const _hoisted_18 = { class: "welcome-desktop-list-appliances" }
const _hoisted_19 = { class: "WelcomeDesktop__hide" }
const _hoisted_20 = {
  key: 0,
  class: "heating-section-quick-access"
}
const _hoisted_21 = { class: "heating-section-quick-access-block" }
const _hoisted_22 = { class: "heating-section-quick-access-block" }
const _hoisted_23 = { class: "heating-section-quick-access-block-buttons" }
const _hoisted_24 = {
  key: 1,
  class: "heating-section-quick-access"
}
const _hoisted_25 = { class: "heating-section-quick-access-block__full" }
const _hoisted_26 = { class: "heating-section-quick-modes" }
const _hoisted_27 = { class: "welcome-desktop-list WelcomeDesktop__show" }
const _hoisted_28 = { class: "welcome-desktop-list-programs" }
const _hoisted_29 = { class: "heating-section-quick-access-block" }
const _hoisted_30 = { class: "heating-section-quick-access-block-title" }
const _hoisted_31 = { class: "heating-section-quick-access-block" }
const _hoisted_32 = {
  key: 0,
  class: "heating-section-quick-access-block-title"
}
const _hoisted_33 = {
  key: 1,
  class: "heating-section-quick-access-block-title"
}
const _hoisted_34 = { class: "heating-section-quick-access-block-buttons" }
const _hoisted_35 = { class: "welcome-desktop-list-appliances" }
const _hoisted_36 = { class: "WelcomeDesktop__hide" }
const _hoisted_37 = { class: "welcome-conso" }
const _hoisted_38 = { class: "welcome-conso-bottom-container" }
const _hoisted_39 = { class: "welcome-conso-bottom-container-meteo" }
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "heatingHeader__temp" }
const _hoisted_42 = { class: "welcome-conso-bottom-container-diagnostics" }
const _hoisted_43 = ["href"]
const _hoisted_44 = { class: "BottomPopin__buttons" }

import {
  AppliancesModule,
  AuthModule,
  ConsentModule,
  ConsoModule,
  ContractModule,
  DiagnosticModule,
  GeolocationModule,
  HeatingModule,
  ProgramModule,
  SiteModule,
  SwitchConsoModule
} from "@/store";

import ERoutes from "@/router/routes";

import WelcomeService from "@/services/Welcome";
import {TWelcomeInfo} from "@/services/Welcome/interfaces";
import {aggregatedMonthFormat, EAggregationAlias} from "@/services/Conso/interfaces";
import {TQuickSettings} from "@/services/Heating/interfaces";

import {digitalFormat} from "@/helpers/number/formatNumber";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";

import logo from "@/assets/images/logo_beta.svg?url";

import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import MyVLinkyConsentStep
  from "@/components/domains/Profile/MyVFirstConnectionForms/MyVLinkyConsentStep/MyVLinkyConsentStep.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {Checkbox, Divider} from "ant-design-vue";
import MyVCustomDuration from "@/components/domains/Heating/quicksettings/MyVCustomDuration/MyVCustomDuration.vue";
import {Capacitor} from "@capacitor/core";
import ConsoTooltip from "@/components/domains/Conso/MyVConsoTooltip/MyVConsoTooltip.vue";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import MyVProgReminder from "@/components/domains/Heating/programs/MyVProgReminder/MyVProgReminder.vue";
import {EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";
import {isWebViewMobileApp} from "@/helpers/domains/device";
import MyVAppliancesList from "@/components/domains/Heating/appliances/MyVAppliancesList/MyVAppliancesList.vue";
import MyVAppliancesSlider from "@/components/domains/Heating/appliances/MyVAppliancesSlider/MyVAppliancesSlider.vue";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import MyVEcowattAlert from "@/components/ui/organisms/MyVEcowattAlert/MyVEcowattAlert.vue";
import {TEcowattAlerts} from "@/components/ui/organisms/MyVEcowattAlert/interfaces";
import MyVPdlValidationModal
  from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationModal/MyVPdlValidationModal.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVMonthlyConsumptionGraph
  from "@/components/domains/Conso/MyVMonthlyConsumptionGraph/MyVMonthlyConsumptionGraph.vue";
import MyVProgramState from "@/components/domains/Heating/programs/MyVProgramState/MyVProgramState.vue";
import MyVQuickSettings from "@/components/domains/Heating/quicksettings/MyVQuickSettings/MyVQuickSettings.vue";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import {useResponsive} from "@/composables/useResponsive";
import {useRouter} from "vue-router";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import MyVMobileBanner from "@/components/ui/atoms/MyVMobileBanner/MyVMobileBanner.vue";
import MyVCardContainer from "@/components/ui/atoms/MyVCardContainer/MyVCardContainer.vue";
import {ECardContainerColor} from "@/components/ui/atoms/MyVCardContainer/interfaces";
import Cookie from "@/helpers/cookie";
import API, {maxConnectionBeforeShowPopin} from "@/helpers/api";
import {ETemperatureUnit} from "@/components/domains/Heating/MyVTemperature/interfaces";
import {TGeolocationStatus} from "@/services/Geolocation/interfaces";
import {useAppStoreRedirect} from "@/composables/useAppStoreRedirect";
import {ECookieNames} from "@/helpers/cookie/interfaces";
import {useCookieNames} from "@/composables/useCookieNames";
import {useDate} from "@/helpers/dates/date-utils";
import useCurrency from "@/composables/currency";


export default /*@__PURE__*/_defineComponent({
  __name: 'WelcomeView',
  setup(__props) {

const showConsentsPopin = ref(false);
const showConsoInfoPopin = ref(false);
const showPdlValidationPopin = ref(false);
const showProgReminder = ref(false);
const showGeolocationPopinWarnOpen = ref(false);
const dontShowProgReminderAgain = ref(false);
const loading = ref(true);
const consoTypeKey = ref(0);
const showMobileBanner = ref(false);
const isOpenPopinDuration = ref(false);
const hasSetEndDate = ref(false);
const showEcowattAlert = ref(false);
const ecowattAlerts = ref<TEcowattAlerts | undefined>(undefined);
const welcome = ref<TWelcomeInfo | null>(null);
const error = ref<string | null>(null);
const quickSettingsToEnabled = ref<TQuickSettings | null>(null);

const {currency} = useCurrency()
const {isMobile} = useResponsive()
const {progReminderCookie, enedisDateCookie, enedisCounterCookie} = useCookieNames()
const router = useRouter()
const {goToStore} = useAppStoreRedirect()

const currentMonthConso = computed(() => {
  const conso = summaryConsumption.value;
  if (conso) {
    return {
      peakHour: consoType.value ? fromWhToKwh(conso.peakHourConsumptionInWh ?? 0) : conso.peakHourConsumptionInCurrency ?? 0,
      offPeakHour: consoType.value ? fromWhToKwh(conso.offPeakHourConsumptionInWh ?? 0) : conso.offPeakHourConsumptionInCurrency ?? 0,
      total: consoType.value ? fromWhToKwh(conso.totalConsumptionInWh) : conso.totalConsumptionInCurrency ?? 0,
      estimated: consoType.value ? fromWhToKwh(conso.estimatedConsumptionInWh ?? 0) : (conso.estimatedConsumptionInCurrency!) ?? 0,
    }
  }
  return undefined
})

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const iconTemp = computed(() => {
  if (!welcome.value?.iconName) return null
  const images = require.context('../../../../../assets/images', false, /\.png$/);

  return images('./' + welcome.value!.iconName + '.png')
})

const consoType = computed(() => {
  return SwitchConsoModule.switchType;
})

const summaryConsumption = computed(() => {
  return ConsoModule.consumptionFullData.responses[useDate().format(aggregatedMonthFormat)]?.summary;
})

const isAllButOneDiagnosticsOk = computed(() => {
  return DiagnosticModule.isAllButOneDiagnosticsOk
})

const isTestingInProgress = computed(() => {
  return DiagnosticModule.isTestingInProgress
})

const externalTemp = computed(() => {
  if (!welcome.value) {
    return null;
  }
  return welcome.value!.externalTemp ?? null;
})

const programmingState = computed(() => {
  return ProgramModule.programmingState;
})

const isGeolocationWarning = computed(() => {
  return GeolocationModule.isGeolocationWarning;
})

const isMobileDevice = computed(() => {
  const userAgent = window.navigator.userAgent;
  const isIphone = userAgent.match(/iPhone/);
  const isAndroid = userAgent.match(/Android/);
  const isIpad = userAgent.match(/iPad/);

  return isIphone !== null || isAndroid !== null || isIpad !== null;
})

const isQuickModeEnabled = computed(() => {
  return programmingState.value?.programType === EApplianceProgType.Quick
})

const user = computed(() => {
  return AuthModule.user;
})

const shouldDisplayMobileBanner = computed(() => isMobileDevice.value && !Capacitor.isNativePlatform() && !isWebViewMobileApp(window))

const hasLinkyStep = computed(() => {
  return isInstallationDateBefore.value && enedisIsNotAllowed.value && isMigratedUser.value;
})

const enedisIsNotAllowed = computed(() => {
  if (!AuthModule.userOrNull) return false;
  return !ConsentModule.enedisIsAllowed;
})

const isInstallationDateBefore = computed(() => {
  if (!AuthModule.userOrNull) return false;

  const currentSiteId = getCurrentSiteIdFromCookie(AuthModule.user);
  const currentSite = getCurrentSiteObjectFromCookie(currentSiteId);

  const installationDate = currentSite.installationDate;
  if (!installationDate) return true;

  return useDate(currentSite.installationDate).isBefore('2022/06/01')
})

const isMigratedUser = computed(() => {
  return AuthModule.userOrNull?.migratedUser;
})

const isGeolocationActivated = computed(() => {
      return ProgramModule.programmingState?.geolocCurrentlyOn
    }
)

const appliances = computed(() => {
  return AppliancesModule.appliances?.slice().sort((a, b) => {
    if (a.applianceType === EApplianceType.WaterHeater) {
      return a.applianceType !== EApplianceType.WaterHeater ? -1 : 1;
    }
    return a.name.localeCompare(b.name)
  }).map(appliance => ({
    ...appliance,
    link: `${ERoutes.HEATING}/${appliance.id}`
  }));
})

const closePopinDuration = () => {
  isOpenPopinDuration.value = false;
  pageScroll(true);
}

const closePdlValidationPopin = () => {
  showPdlValidationPopin.value = false;
  pageScroll(true);
}

const handleCloseAndRestore = () => {
  showConsoInfoPopin.value = true;
  showPdlValidationPopin.value = false;
}

const quickSettingsSelect = (quickSettings: TQuickSettings) => {
  quickSettingsToEnabled.value = quickSettings;
  isOpenPopinDuration.value = true;
}

const openConsoInfoPopin = () => {
  showConsoInfoPopin.value = true;
  pageScroll(false);
}

const closeWelcomeConsoPopin = () => {
  showConsoInfoPopin.value = false;
  pageScroll(true);
}

const openProgReminder = () => {
  const cookie = Cookie.get(progReminderCookie.value);
  if (!cookie) {
    showProgReminder.value = true;
    pageScroll(false);
  }
}

const closeProgReminder = () => {
  showProgReminder.value = false;
  pageScroll(true);
  if (dontShowProgReminderAgain.value) {
    Cookie.set(progReminderCookie.value, '1');
  }
}

const openEcowattAlerts = () => {
  showEcowattAlert.value = true;
  pageScroll(false);
}

const closeEcowattAlerts = () => {
  showEcowattAlert.value = false;
  pageScroll(true);
}

const getWelcomeConso = async () => {
  return ConsoModule.getConsumptionFullData({
    period: EAggregationAlias.Month,
    dates: useDate().format(aggregatedMonthFormat)
  })
}

const handleQuicksettingSelect = (quicksetting: TQuickSettings) => {
  if (isGeolocationActivated.value && ProgramModule.programmingState?.programType === EApplianceProgType.Quick) {
    router.push(ERoutes.PROGRAM)
  } else {
    quickSettingsSelect(quicksetting)
  }
}
const handleStopQuicksetting = () => {
  if (isGeolocationActivated.value && ProgramModule.programmingState?.programType === EApplianceProgType.Quick) {
    showGeolocationPopinWarnOpen.value = true
  } else {
    HeatingModule.stopQuickSettings()
  }
}
/*TODO Duplicate code ProgramlistView, refactor !*/
const handleAutoGeolocationDisabling = () => {
  HeatingModule.stopQuickSettings()?.then(() => {
    GeolocationModule.refreshGeofences()

    const statusPayload: TGeolocationStatus = {
      radius: GeolocationModule.status?.radius,
      activatedByUser: false,
    }

    GeolocationModule.putStatus(statusPayload)
    HeatingModule.getQuickSettings(true)
    ProgramModule.getProgrammingState()
  })
      .finally(() => showGeolocationPopinWarnOpen.value = false)
}

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
        }
      })
  consoTypeKey.value++;
}

const checkEnedisCookie = () => {
  const counterCookie = Cookie.get(enedisCounterCookie.value);
  const dateCookie = Cookie.get(enedisDateCookie.value);

  if ((!counterCookie && !dateCookie) || !hasLinkyStep.value) return false;
  if (dateCookie === 'true') {
    showConsentsPopin.value = true;
  } else if (parseInt(counterCookie as string) == maxConnectionBeforeShowPopin) {
    showConsentsPopin.value = true;
    Cookie.set(enedisCounterCookie.value, '0');
  }
}


const closeConsentPopin = () => {
  Cookie.set(enedisDateCookie.value, 'false');
  showConsentsPopin.value = false;
}

const getEcowattAlerts = () => {
  if (SiteModule.isSiteCountryFrance) {
    API.get<TEcowattAlerts>(`/api/${getCurrentSiteIdFromCookie(user.value)}/rte-alerts`).then(({data}) => {
      if (data && data.rteAlerts.length) {
        ecowattAlerts.value = data! as TEcowattAlerts;
        openEcowattAlerts();
      }
    })
  }
}

const handleMobileBannerDisplay = () => {
  if (shouldDisplayMobileBanner.value && Cookie.isExpired(ECookieNames.MOBILE_BANNER_DISPLAY_COOKIE)) {
    showMobileBanner.value = true
  }
}

const handleMobileBannerHide = () => {
  showMobileBanner.value = false
  Cookie.set(ECookieNames.MOBILE_BANNER_DISPLAY_COOKIE, 'true', {expires: useDate().add(3, 'days').toDate()})
}

onBeforeMount(async () => {
  loading.value = true;

  await Promise.all([
    GeolocationModule.refreshGeofences(),
    ProgramModule.getProgrammingState(),
    HeatingModule.getQuickSettings(),
    ProgramModule.getPrograms(),
    ContractModule.getContractsList(),
    getWelcomeConso(),
    GeolocationModule.getDeviceInfo(),
    GeolocationModule.getStatus(),
    GeolocationModule.getPhones(),
    GeolocationModule.getDeviceLocationState(),
    isSiteCountryFrance.value ? ContractModule.getPdlValidity(true) : undefined,
    AppliancesModule.getAppliances(true),
    SiteModule.getSites()])
      .catch(e => {
        error.value = e.response?.data.message;
      }).finally(() => {
        loading.value = false;
      });

  await WelcomeService.getWelcomeInformations(getCurrentSiteIdFromCookie(user.value))
      .then(welcomeMessage => {
        welcome.value = welcomeMessage!;
      }).catch(e => {
        error.value = e.response?.data.message;
      })

  if (!isMobile.value) {
    await ProgramModule.getEnergyManager();
  }

  getEcowattAlerts();
  checkEnedisCookie();
  handleMobileBannerDisplay();
})

watch(summaryConsumption, (newValue) => {
  if (!newValue) {
    getWelcomeConso()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageMain, {
    homeLink: _unref(ERoutes).HOME,
    logo: _unref(logo),
    title: _ctx.$t('app.title'),
    class: "welcome-view inner-sm-min"
  }, {
    default: _withCtx(() => [
      (!loading.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (showMobileBanner.value)
              ? (_openBlock(), _createBlock(MyVMobileBanner, {
                  key: 0,
                  onClose: handleMobileBannerHide,
                  onGoToStore: _unref(goToStore)
                }, null, 8, ["onGoToStore"]))
              : _createCommentVNode("", true),
            (error.value)
              ? (_openBlock(), _createBlock(MyVErrorBanner, {
                  key: 1,
                  class: "error-banner",
                  error: error.value,
                  onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value = null))
                }, null, 8, ["error"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("section", _hoisted_2, [
                _createVNode(MyVCardContainer, {
                  "hide-header": _unref(isMobile),
                  "content-color": _unref(isMobile) ? _unref(ECardContainerColor).blue : _unref(ECardContainerColor).white
                }, {
                  left: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('welcome.devicesManagement.title')), 1)
                  ]),
                  content: _withCtx(() => [
                    (!isQuickModeEnabled.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('welcome.buttons.heating_mode.program')), 1),
                            _createVNode(MyVProgramState, {
                              "onUpdate:quickSetting": handleQuicksettingSelect,
                              "onUpdate:stop": handleStopQuicksetting
                            })
                          ]),
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('programList.menu.quick_modes')), 1),
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(MyVQuickSettings, {
                                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (quickSettingsSelect($event)))
                              })
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", null, [
                              (!isGeolocationActivated.value)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('global.quickSettings.end')), 1)
                                  ], 64))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", null, _toDisplayString(_unref(ProgramModule).endModeTitle), 1)
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(MyVProgramState, {
                                "onUpdate:quickSetting": handleQuicksettingSelect,
                                "onUpdate:stop": handleStopQuicksetting
                              })
                            ])
                          ])
                        ])),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('welcome.buttons.heating_mode.program')), 1),
                          (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                            ? (_openBlock(), _createBlock(MyVButton, {
                                key: 0,
                                type: "link",
                                class: "heating-section-quick-access-block-idle-program",
                                round: "",
                                to: _unref(ERoutes).PROGRAM
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(MyVIcon, {
                                    name: _unref(ProgramModule).activeProgram?.name ? 'pause2' : 'power1'
                                  }, null, 8, ["name"]),
                                  _createTextVNode(" " + _toDisplayString(_unref(ProgramModule).activeProgram?.name ? _ctx.$t(_unref(ProgramModule).activeProgram?.name) : _ctx.$t('global.no_program')), 1)
                                ]),
                                _: 1
                              }, 8, ["to"]))
                            : (_openBlock(), _createBlock(MyVProgramState, {
                                key: 1,
                                "onUpdate:quickSetting": handleQuicksettingSelect,
                                "onUpdate:stop": handleStopQuicksetting
                              }))
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                (!isGeolocationActivated.value)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('global.quickSettings.end')), 1)
                                    ], 64))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(_unref(ProgramModule).endModeTitle), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t('programList.menu.quick_modes')), 1)),
                          _createElementVNode("div", _hoisted_17, [
                            (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                              ? (_openBlock(), _createBlock(MyVProgramState, {
                                  key: 0,
                                  "onUpdate:stop": handleStopQuicksetting,
                                  "onUpdate:quickSetting": handleQuicksettingSelect
                                }))
                              : (_openBlock(), _createBlock(MyVQuickSettings, {
                                  key: 1,
                                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (quickSettingsSelect($event)))
                                }))
                          ])
                        ])
                      ]),
                      _createVNode(_unref(Divider)),
                      _createElementVNode("div", _hoisted_18, [
                        (!!appliances.value)
                          ? (_openBlock(), _createBlock(MyVAppliancesList, {
                              key: 0,
                              appliances: appliances.value
                            }, null, 8, ["appliances"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      (!!appliances.value)
                        ? (_openBlock(), _createBlock(MyVAppliancesSlider, {
                            key: 0,
                            appliances: appliances.value
                          }, null, 8, ["appliances"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    (!isQuickModeEnabled.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('welcome.buttons.heating_mode.program')), 1),
                            _createVNode(MyVProgramState, {
                              "onUpdate:quickSetting": handleQuicksettingSelect,
                              "onUpdate:stop": handleStopQuicksetting
                            })
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('programList.menu.quick_modes')), 1),
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(MyVQuickSettings, {
                                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (quickSettingsSelect($event)))
                              })
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", null, [
                              (!isGeolocationActivated.value)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('global.quickSettings.end')), 1)
                                  ], 64))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", null, _toDisplayString(_unref(ProgramModule).endModeTitle), 1)
                            ]),
                            _createElementVNode("div", _hoisted_26, [
                              _createVNode(MyVProgramState, {
                                "onUpdate:quickSetting": handleQuicksettingSelect,
                                "onUpdate:stop": handleStopQuicksetting
                              })
                            ])
                          ])
                        ])),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('welcome.buttons.heating_mode.program')), 1),
                          (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                            ? (_openBlock(), _createBlock(MyVButton, {
                                key: 0,
                                type: "link",
                                class: "heating-section-quick-access-block-idle-program",
                                round: "",
                                to: _unref(ERoutes).PROGRAM
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(MyVIcon, {
                                    name: _unref(ProgramModule).activeProgram?.name ? 'pause2' : 'power1'
                                  }, null, 8, ["name"]),
                                  _createTextVNode(" " + _toDisplayString(_unref(ProgramModule).activeProgram?.name ? _ctx.$t(_unref(ProgramModule).activeProgram?.name) : _ctx.$t('global.no_program')), 1)
                                ]),
                                _: 1
                              }, 8, ["to"]))
                            : (_openBlock(), _createBlock(MyVProgramState, {
                                key: 1,
                                "onUpdate:quickSetting": handleQuicksettingSelect,
                                "onUpdate:stop": handleStopQuicksetting
                              }))
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                          (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                (!isGeolocationActivated.value)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(_toDisplayString(_ctx.$t('global.quickSettings.end')), 1)
                                    ], 64))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(_unref(ProgramModule).endModeTitle), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(_ctx.$t('programList.menu.quick_modes')), 1)),
                          _createElementVNode("div", _hoisted_34, [
                            (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                              ? (_openBlock(), _createBlock(MyVProgramState, {
                                  key: 0,
                                  "onUpdate:stop": handleStopQuicksetting,
                                  "onUpdate:quickSetting": handleQuicksettingSelect
                                }))
                              : (_openBlock(), _createBlock(MyVQuickSettings, {
                                  key: 1,
                                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (quickSettingsSelect($event)))
                                }))
                          ])
                        ])
                      ]),
                      _createVNode(_unref(Divider)),
                      _createElementVNode("div", _hoisted_35, [
                        (!!appliances.value)
                          ? (_openBlock(), _createBlock(MyVAppliancesList, {
                              key: 0,
                              appliances: appliances.value
                            }, null, 8, ["appliances"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      (!!appliances.value)
                        ? (_openBlock(), _createBlock(MyVAppliancesSlider, {
                            key: 0,
                            appliances: appliances.value
                          }, null, 8, ["appliances"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["hide-header", "content-color"])
              ]),
              _createElementVNode("section", _hoisted_37, [
                _createVNode(MyVCardContainer, {
                  "content-color": _unref(isMobile) ? _unref(ECardContainerColor).blue : _unref(ECardContainerColor).white
                }, _createSlots({
                  content: _withCtx(() => [
                    (currentMonthConso.value)
                      ? (_openBlock(), _createBlock(MyVMonthlyConsumptionGraph, {
                          key: 0,
                          data: currentMonthConso.value
                        }, null, 8, ["data"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        (iconTemp.value)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: iconTemp.value,
                              alt: "",
                              class: "icon icon-meteo"
                            }, null, 8, _hoisted_40))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_41, _toDisplayString(externalTemp.value || externalTemp.value === 0 ? _unref(digitalFormat)(externalTemp.value) : '-') + _toDisplayString(_unref(ETemperatureUnit).Celsius), 1)
                      ]),
                      _createElementVNode("div", _hoisted_42, [
                        (isGeolocationWarning.value)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push(_unref(ERoutes).PROGRAM)))
                            }, [
                              _createVNode(MyVIcon, { name: "pin" }),
                              _cache[14] || (_cache[14] = _createElementVNode("svg", {
                                class: "icon icon-exclamation-circle icon__warning",
                                "aria-hidden": "true"
                              }, [
                                _createElementVNode("use", { href: "#icon-exclamation-circle " })
                              ], -1))
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(router).push(_unref(ERoutes).DIAGNOSTIC)))
                        }, [
                          _createVNode(MyVIcon, { name: "wrench" }),
                          (_openBlock(), _createElementBlock("svg", {
                            class: _normalizeClass(['icon', isAllButOneDiagnosticsOk.value ? 'icon-checkmark icon__success' : isTestingInProgress.value ? 'icon-spinner' : 'icon-exclamation-circle icon__warning']),
                            "aria-hidden": "true"
                          }, [
                            _createElementVNode("use", {
                              href: isAllButOneDiagnosticsOk.value ? '#icon-checkmark' :  isTestingInProgress.value ? '#icon-spinner': '#icon-exclamation-circle'
                            }, null, 8, _hoisted_43)
                          ], 2))
                        ])
                      ])
                    ])
                  ]),
                  _: 2
                }, [
                  (currentMonthConso.value)
                    ? {
                        name: "left",
                        fn: _withCtx(() => [
                          _createElementVNode("div", {
                            class: "welcome-conso-header-title",
                            onClick: openConsoInfoPopin,
                            "data-testid": "welcome-conso-header"
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('conso.chart.consumption.month')) + " ", 1),
                            _cache[13] || (_cache[13] = _createElementVNode("svg", {
                              class: "icon icon-question-circle",
                              "aria-hidden": "true"
                            }, [
                              _createElementVNode("use", { "xlink:href": "#icon-question-circle" })
                            ], -1))
                          ])
                        ]),
                        key: "0"
                      }
                    : undefined,
                  (currentMonthConso.value)
                    ? {
                        name: "right",
                        fn: _withCtx(() => [
                          _createVNode(MyVDisplayGroupStore, {
                            currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT,
                            hideIfDisabled: true
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createBlock(MyVAntdswitch, {
                                value: consoType.value,
                                defaultValue: consoType.value,
                                key: consoTypeKey.value,
                                checkedChildren: _unref(currency),
                                size: "small",
                                unCheckedChildren: _unref(EEnergyUnit).kWh,
                                onChange: setConsoType
                              }, null, 8, ["value", "defaultValue", "checkedChildren", "unCheckedChildren"]))
                            ]),
                            _: 1
                          }, 8, ["currentRight"])
                        ]),
                        key: "1"
                      }
                    : undefined
                ]), 1032, ["content-color"])
              ])
            ])
          ], 64))
        : _createCommentVNode("", true),
      (showGeolocationPopinWarnOpen.value)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? MyVBottomPopin : MyVPageWithReturn), {
            key: 1,
            onClose: _cache[8] || (_cache[8] = ($event: any) => (showGeolocationPopinWarnOpen.value = false)),
            title: _ctx.$t('programList.geolocation.disabled.warn.shortLeave.title') ,
            type: "popin",
            "return-action": () => showGeolocationPopinWarnOpen.value = false,
            onCancel: _cache[9] || (_cache[9] = ($event: any) => (showGeolocationPopinWarnOpen.value = false))
          }, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('programList.geolocation.disabled.warn.shortLeave.title')), 1)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t('programList.geolocation.disabled.warn.subtitle')) + " ", 1),
              _createElementVNode("div", _hoisted_44, [
                _createVNode(MyVButton, {
                  class: "BottomPopin__confirm",
                  onClick: handleAutoGeolocationDisabling
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(MyVButton, {
                  class: "BottomPopin__cancel",
                  primary: false,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (showGeolocationPopinWarnOpen.value = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 40, ["title", "return-action"]))
        : _createCommentVNode("", true),
      (isOpenPopinDuration.value)
        ? (_openBlock(), _createBlock(MyVCustomDuration, {
            key: 2,
            onClose: closePopinDuration,
            onHasEndDate: _cache[10] || (_cache[10] = ($event: any) => (hasSetEndDate.value = true)),
            quickSettingsToEnabled: quickSettingsToEnabled.value,
            onOpenReminder: openProgReminder
          }, null, 8, ["quickSettingsToEnabled"]))
        : _createCommentVNode("", true),
      (showConsentsPopin.value)
        ? (_openBlock(), _createBlock(MyVPageWithReturn, {
            key: 3,
            title: _ctx.$t('firstConnection.linky_consent.title'),
            type: "popin",
            "return-action": closeConsentPopin
          }, {
            default: _withCtx(() => [
              _createVNode(MyVLinkyConsentStep, {
                class: "WelcomeLinkyPopup",
                next: () => showConsentsPopin.value = false
              }, null, 8, ["next"])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (showEcowattAlert.value && ecowattAlerts.value)
        ? (_openBlock(), _createBlock(MyVEcowattAlert, {
            key: 4,
            ecowattAlerts: ecowattAlerts.value,
            onClose: closeEcowattAlerts,
            onUpdateEcowattConsent: getEcowattAlerts
          }, null, 8, ["ecowattAlerts"]))
        : _createCommentVNode("", true),
      (showConsoInfoPopin.value)
        ? (_openBlock(), _createBlock(ConsoTooltip, {
            key: 5,
            onClose: closeWelcomeConsoPopin,
            "onConsoTooltip::validatePdl": _cache[11] || (_cache[11] = ($event: any) => (showPdlValidationPopin.value = true))
          }))
        : _createCommentVNode("", true),
      (showPdlValidationPopin.value)
        ? (_openBlock(), _createBlock(MyVPdlValidationModal, {
            key: 6,
            onClose: closePdlValidationPopin,
            onCloseAndRestore: handleCloseAndRestore
          }))
        : _createCommentVNode("", true),
      (showProgReminder.value)
        ? (_openBlock(), _createBlock(MyVPageWithReturn, {
            key: 7,
            class: "ProgReminderPopin",
            title: _ctx.$t('prog_reminder.title'),
            type: "popin",
            "return-action": closeProgReminder
          }, {
            default: _withCtx(() => [
              _createVNode(MyVProgReminder),
              _createVNode(MyVButton, {
                onClick: closeProgReminder,
                class: "closeButton"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.ok')), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(Checkbox), {
                class: "dontAskAgain",
                checked: dontShowProgReminderAgain.value,
                "onUpdate:checked": _cache[12] || (_cache[12] = ($event: any) => ((dontShowProgReminderAgain).value = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.dont_ask_again')), 1)
                ]),
                _: 1
              }, 8, ["checked"])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["homeLink", "logo", "title"]))
}
}

})