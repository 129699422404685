import {TTabNavItem} from "@/components/ui/molecules/MyVTabNav/interfaces";
import ERoutes from "@/router/routes";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import dayjs from "dayjs";
import {aggregatedMonthFormat} from "@/services/Conso/interfaces";

export const TabNavFakeDatas: TTabNavItem[] = [
    {
        icon: 'menu-home',
        title: 'nav.home',
        link: ERoutes.HOME,
        disabled: false
    },
    {
        icon: 'graph',
        title: 'nav.conso',
        link: {
            path: ERoutes.CONSO,
            query: {
                date:  dayjs().format(aggregatedMonthFormat),
                type: 'month'
            }
        },
        disabled: false
    },
    {
        icon: 'temperature',
        title: 'nav.heating',
        link: ERoutes.HEATING,
        disabled: false
    },
    {
        icon: 'menu-advices',
        title: 'nav.diagnostics',
        link: ERoutes.ADVICES,
        disabled: false,
        right: EDisplayGroupRightName.MY_ADVICES,
    },
];