import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "inner-sm-min" }
const _hoisted_3 = { class: "heating-view-content" }
const _hoisted_4 = { class: "heating-view-header" }
const _hoisted_5 = {
  key: 0,
  class: "heating-view-header-quick-access mobile"
}
const _hoisted_6 = { class: "heating-view-header-quick-access-title" }
const _hoisted_7 = { class: "heating-view-header-quick-access" }
const _hoisted_8 = {
  key: 0,
  class: "heating-view-header-quick-access-title"
}
const _hoisted_9 = {
  key: 1,
  class: "heating-view-header-quick-access-title"
}
const _hoisted_10 = {
  key: 1,
  class: "heating-view-header-quick-access"
}
const _hoisted_11 = { class: "heating-view-header-quick-access-title" }
const _hoisted_12 = {
  key: 2,
  class: "mobilePopin"
}

import {AppliancesModule, AuthModule, HeatingModule, ProgramModule} from "@/store";

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import logo from "@/assets/images/logo_beta.svg?url";
import ERoutes from "@/router/routes";
import {TQuickSettings} from "@/services/Heating/interfaces";
import ErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import MyVCustomDuration from "@/components/domains/Heating/quicksettings/MyVCustomDuration/MyVCustomDuration.vue";
import ProgReminder from "@/components/domains/Heating/programs/MyVProgReminder/MyVProgReminder.vue";
import {Checkbox, Divider} from "ant-design-vue";
import Cookie from "@/helpers/cookie";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import MyVAppliancesList from "@/components/domains/Heating/appliances/MyVAppliancesList/MyVAppliancesList.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useResponsive} from "@/composables/useResponsive";
import MyVProgramState from "@/components/domains/Heating/programs/MyVProgramState/MyVProgramState.vue";
import MyVQuickSettings from "@/components/domains/Heating/quicksettings/MyVQuickSettings/MyVQuickSettings.vue";
import MyVCardContainer from "@/components/ui/atoms/MyVCardContainer/MyVCardContainer.vue";
import {ECardContainerColor} from "@/components/ui/atoms/MyVCardContainer/interfaces";
import useApplianceLoader from "@/composables/useAppliance";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVRenameAppliance from "@/components/domains/Device/MyVRenameAppliance/MyVRenameAppliance.vue";
import {TRenameApplianceDatas} from "@/components/domains/Device/MyVRenameAppliance/interfaces";
import {applianceTypeName} from "@/helpers/domains/applianceProgType";
import {useI18n} from "vue-i18n";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {useCookieNames} from "@/composables/useCookieNames";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeatingView',
  setup(__props) {

const programBarIsLoaded = ref(false);
const error = ref<string | null>(null);
const isOpenPopinDuration = ref(false);
const hasSetEndDate = ref(false);
const showGeAlert = ref(false);
const showProgReminder = ref(false);
const dontShowProgReminderAgain = ref(false);
const quickSettingsToEnabled = ref<TQuickSettings | null>(null);
const isOpenPopinEdit = ref(false);

const route = useRoute()
const router = useRouter()
const {t} = useI18n()
const {isMobile} = useResponsive()
const {loadedAppliance} = useApplianceLoader()
const {progReminderCookie} = useCookieNames()

const appliances = computed(() => {
  return AppliancesModule.appliances?.slice().sort((a, b) => {
    if (a.applianceType === EApplianceType.WaterHeater) {
      return a.applianceType !== EApplianceType.WaterHeater ? -1 : 1;
    }
    return a.name.localeCompare(b.name)
  }).map(appliance => ({
    ...appliance,
    link: `${ERoutes.HEATING}/${appliance.id}`
  }));
})


const user = computed(() => {
  return AuthModule.user;
})

const isGeolocationActivated = computed(() => {
      return ProgramModule.programmingState?.geolocCurrentlyOn
    }
)

const currentSiteId = computed(() => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed(() => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const quickSettingsSelect = (quickSettings: TQuickSettings) => {
  quickSettingsToEnabled.value = quickSettings;
  isOpenPopinDuration.value = true;
}

const applianceTypeTrad = (applianceType: EApplianceType) => {
  return t(applianceTypeName(applianceType))
}

const submitRename = (formDatas: TRenameApplianceDatas) => {
  AppliancesModule.updateAppliance({
    id: parseInt(route.params.id as string),
    body: {
      name: formDatas.applianceName
    }
  })
      .then(() => {
        AppliancesModule.getAppliances(true)
        closePopinEdit();
      });
}

const closePopinDuration = () => {
  isOpenPopinDuration.value = false;
  pageScroll(true);
}

const closePopinEdit = () => {
  isOpenPopinEdit.value = false;
  pageScroll(true);
}

const openProgReminder = () => {
  const cookie = Cookie.get(progReminderCookie.value)
  if (!cookie) {
    showProgReminder.value = true;
    pageScroll(false);
  }
}

const handleRename = () => {
  isOpenPopinEdit.value = true
}

const closeProgReminder = () => {
  showProgReminder.value = false;
  pageScroll(true);
  if (dontShowProgReminderAgain.value) {
    Cookie.set(progReminderCookie.value, '1');
  }
}

const handleQuicksettingSelect = (quicksetting: TQuickSettings) => {
  if (ProgramModule.programmingState?.geolocCurrentlyOn && ProgramModule.programmingState.programType === EApplianceProgType.Quick) {
    router.push(ERoutes.PROGRAM)
  } else {
    quickSettingsSelect(quicksetting)
  }
}
const handleStopQuicksetting = () => {
  HeatingModule.stopQuickSettings()
}

onBeforeMount(async () => {
  // Force appliance request to update appliance everytime
  // Might become depreciated if websocket with front synchronization system is done.
  await AppliancesModule.getAppliances(true).then(() => {
    if (window.innerWidth >= 768) {
      if (appliances.value && appliances.value[0] && !route.params.id) {
        router.replace(appliances.value[0].link);
      }
    }
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur';
  });

  Promise.all([
    HeatingModule.getQuickSettings(),
    ProgramModule.getPrograms()
  ])
      .then(() => {
        programBarIsLoaded.value = true;
      }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur';
  });

  ProgramModule.getProgrammingState();
  ProgramModule.getEnergyManager();
})

watch(route, (newValue) => {
  if (!newValue.params.id && appliances.value && !isMobile.value) {
    router.replace(appliances.value[0].link);
  }
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(MyVPageMain, {
    homeLink: _unref(ERoutes).HOME,
    logo: _unref(logo),
    title: _ctx.$t('app.title'),
    hasProgramBar: true,
    class: "heating-view"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVHeaderMainPage, {
          title: "heating.title",
          isFromConsoOrHeater: "",
          site: currentSite.value
        }, {
          HeaderMainPage__right: _withCtx(() => [
            _createVNode(MyVDisplayGroupStore, {
              currentRight: _unref(EDisplayGroupRightName).NEWSCHEDULER,
              hideIfDisabled: true
            }, {
              default: _withCtx(() => [
                _createVNode(MyVButton, {
                  type: _unref(EButtonType).Link,
                  round: "",
                  primary: "",
                  to: _unref(ERoutes).PROGRAM
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button.manage_programs')), 1)
                  ]),
                  _: 1
                }, 8, ["type", "to"])
              ]),
              _: 1
            }, 8, ["currentRight"])
          ]),
          _: 1
        }, 8, ["site"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (error.value)
          ? (_openBlock(), _createBlock(ErrorBanner, {
              key: 0,
              error: error.value,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value=null)),
              class: "error-banner heating"
            }, null, 8, ["error"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(MyVCardContainer, {
            "content-color": _unref(ECardContainerColor).white,
            "hide-header": _unref(isMobile)
          }, {
            left: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('welcome.devicesManagement.title')), 1)
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('welcome.buttons.heating_mode.program')), 1),
                      _createVNode(MyVButton, {
                        type: _unref(EButtonType).Link,
                        class: "button-idle-program",
                        round: "",
                        to: _unref(ERoutes).PROGRAM
                      }, {
                        default: _withCtx(() => [
                          _createVNode(MyVIcon, {
                            name: _unref(ProgramModule).activeProgram?.name ? 'pause2' : 'power1'
                          }, null, 8, ["name"]),
                          _createTextVNode(" " + _toDisplayString(_unref(ProgramModule).activeProgram?.name ? _ctx.$t(_unref(ProgramModule).activeProgram?.name) : _ctx.$t('global.no_program')), 1)
                        ]),
                        _: 1
                      }, 8, ["type", "to"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  (_unref(ProgramModule).programmingState?.programType === _unref(EApplianceProgType).Quick)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (!isGeolocationActivated.value)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.$t('global.quickSettings.end')), 1)
                            ], 64))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(_unref(ProgramModule).endModeTitle), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('welcome.buttons.heating_mode.program')), 1)),
                  _createVNode(MyVProgramState, {
                    "onUpdate:quickSetting": handleQuicksettingSelect,
                    "onUpdate:stop": handleStopQuicksetting
                  })
                ]),
                (_unref(ProgramModule).programmingState?.programType !== _unref(EApplianceProgType).Quick)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('programList.menu.quick_modes')), 1),
                      _createVNode(MyVQuickSettings, {
                        "onUpdate:value": _cache[1] || (_cache[1] = quickSetting => quickSettingsSelect(quickSetting))
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_unref(Divider)),
              (appliances.value !== null)
                ? (_openBlock(), _createBlock(MyVAppliancesList, {
                    key: 0,
                    appliances: appliances.value,
                    showGeAlert: showGeAlert.value
                  }, null, 8, ["appliances", "showGeAlert"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["content-color", "hide-header"]),
          (!_unref(isMobile))
            ? (_openBlock(), _createBlock(MyVCardContainer, {
                key: 0,
                "content-color": _unref(ECardContainerColor).white
              }, {
                left: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(loadedAppliance)?.name), 1)
                ]),
                right: _withCtx(() => [
                  _createVNode(MyVIcon, {
                    onClick: handleRename,
                    name: "pen"
                  })
                ]),
                content: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_router_view, {
                    key: _ctx.$route.fullPath
                  }))
                ]),
                _: 1
              }, 8, ["content-color"]))
            : (_openBlock(), _createBlock(_component_router_view, {
                key: _ctx.$route.fullPath
              }))
        ])
      ]),
      (isOpenPopinDuration.value)
        ? (_openBlock(), _createBlock(MyVCustomDuration, {
            key: 0,
            onClose: closePopinDuration,
            onHasEndDate: _cache[2] || (_cache[2] = ($event: any) => (hasSetEndDate.value = true)),
            quickSettingsToEnabled: quickSettingsToEnabled.value,
            onOpenReminder: openProgReminder
          }, null, 8, ["quickSettingsToEnabled"]))
        : _createCommentVNode("", true),
      (showProgReminder.value)
        ? (_openBlock(), _createBlock(MyVPageWithReturn, {
            key: 1,
            class: "ProgReminderPopin",
            title: _ctx.$t('prog_reminder.title'),
            type: "popin",
            "return-action": closeProgReminder
          }, {
            default: _withCtx(() => [
              _createVNode(ProgReminder),
              _createVNode(MyVButton, {
                onClick: closeProgReminder,
                class: "closeButton"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.ok')), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(Checkbox), {
                class: "dontAskAgain",
                checked: dontShowProgReminderAgain.value,
                "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((dontShowProgReminderAgain).value = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.dont_ask_again')), 1)
                ]),
                _: 1
              }, 8, ["checked"])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
      (isOpenPopinEdit.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(MyVPageWithReturn, {
              title: _ctx.$t('heating.edit.title'),
              returnAction: closePopinEdit,
              type: "popin"
            }, {
              default: _withCtx(() => [
                _createVNode(MyVRenameAppliance, {
                  returnAction: closePopinEdit,
                  onSubmit: submitRename,
                  applianceName: _unref(loadedAppliance)?.name,
                  applianceType: applianceTypeTrad(_unref(loadedAppliance)?.applianceType)
                }, null, 8, ["applianceName", "applianceType"])
              ]),
              _: 1
            }, 8, ["title"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["homeLink", "logo", "title"]))
}
}

})