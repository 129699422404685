import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref } from "vue"

const _hoisted_1 = ["src", "alt"]

import {TConsoHeader} from "./interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import ERoutes from "@/router/routes";
import {TabNavFakeDatas} from "@/dummies/TabNavFakeDatas";
import {Dropdown, Menu} from "ant-design-vue";
import {AuthModule} from "@/store";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import MyVTabNavStore from "@/components/ui/molecules/MyVTabNav/MyVTabNavStore.vue";

interface Props {
  title: TConsoHeader["title"];
  isDetail?: TConsoHeader["isDetail"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVConsoHeader',
  props: {
    title: { default: undefined },
    isDetail: { type: Boolean, default: false }
  },
  setup(__props: any) {




const dropdownVisible = ref(false);
const {t} = useI18n()
const route = useRoute()

const currentLink = computed(() => {
  const name = route.name;
  const type = route.query.type;

  if (name === ERoutes.CONSO_LIVE) {
    return t('conso.view.live');
  } else if (name === ERoutes.CONSO_WEEK || type === ERoutes.CONSO_WEEK) {
    return t('conso.view.weekly');
  } else if (name === ERoutes.CONSO_YEAR || type === ERoutes.CONSO_YEAR) {
    return t('conso.view.annual');
  }

  return t('conso.view.monthly');
})

const links = computed(() => {
  return [
    {
      value: ERoutes.CONSO_LIVE,
      label: t('conso.view.live'),
      url: `${ERoutes.CONSO}/live`,
    },
    {
      value: ERoutes.CONSO_WEEK,
      label: t('conso.view.weekly'),
      url: {path: ERoutes.CONSO, query: {type: 'week'}},
    },
    {
      value: ERoutes.CONSO_MONTH,
      label: t('conso.view.monthly'),
      url: ERoutes.CONSO,
    },
    {
      value: ERoutes.CONSO_YEAR,
      label: t('conso.view.annual'),
      url: {path: ERoutes.CONSO, query: {type: 'year'}},
    },
  ];
})

const tabLinks = computed(() => {
  return TabNavFakeDatas.map(n => ({...n, title: t(n.title)}));
})

const user = computed(() => {
  return AuthModule.user
})

const handleDropdown = (visible: boolean) => {
  dropdownVisible.value = visible;
}

const activeLink = (val: string) => {
  const routeType = route.query.type
  if (routeType) {
    if (routeType === val && route.name === val) {
      return true
    } else if (routeType === val) {
      return true
    }

    return false
  } else {
    return route.name === val;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.ConsoHeader])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.ConsoHeader__header])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.ConsoHeader__title])
        }, _toDisplayString(_ctx.title), 3),
        (user.value.displayGroup.resources.logo)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/",
              class: _normalizeClass([_ctx.$style.ConsoHeader__logo])
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: user.value.displayGroup.resources.logo,
                  alt: _ctx.title,
                  class: "PageMain__logo-img"
                }, null, 8, _hoisted_1)
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([[_ctx.isDetail ? _ctx.$style.ConsoHeader__isDetail : ''], "PageConso__mainNav"])
        }, [
          _createVNode(MyVTabNavStore, { listItem: tabLinks.value }, null, 8, ["listItem"])
        ], 2),
        _createVNode(_unref(Dropdown), {
          class: "__view",
          visible: dropdownVisible.value,
          onVisibleChange: handleDropdown
        }, {
          overlay: _withCtx(() => [
            _createVNode(_unref(Menu), { class: "menu" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link, index) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: index,
                    to: link.url,
                    class: _normalizeClass(["item", [activeLink(link.value) ? _ctx.$style.active : '']]),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (dropdownVisible.value = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(link.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(MyVButton, {
              class: _normalizeClass([_ctx.$style.button]),
              round: "",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(currentLink.value) + " ", 1),
                _cache[1] || (_cache[1] = _createElementVNode("svg", {
                  class: "icon icon-triangle",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-triangle" })
                ], -1))
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        }, 8, ["visible"])
      ], 2)
    ], 2)
  ]))
}
}

})