import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "PageLive" }
const _hoisted_2 = { class: "PageConso__headerButtons" }
const _hoisted_3 = { class: "page2Col" }
const _hoisted_4 = { class: "page2Col__aside" }
const _hoisted_5 = { class: "BarChart__area" }
const _hoisted_6 = { class: "__header" }
const _hoisted_7 = { class: "__title" }
const _hoisted_8 = { class: "__value" }
const _hoisted_9 = { class: "__unit" }
const _hoisted_10 = { class: "__subtitle" }
const _hoisted_11 = { class: "__contentHeader" }
const _hoisted_12 = {
  key: 0,
  class: "__content"
}
const _hoisted_13 = {
  key: 1,
  class: "__content flex"
}
const _hoisted_14 = { class: "stepSwitch" }
const _hoisted_15 = { class: "consumptionInfos" }
const _hoisted_16 = { class: "__header" }
const _hoisted_17 = { class: "__subtitle" }
const _hoisted_18 = { class: "__subtitle" }
const _hoisted_19 = { class: "__title" }
const _hoisted_20 = { class: "__value" }
const _hoisted_21 = { class: "__unit" }
const _hoisted_22 = { class: "page2Col__main" }
const _hoisted_23 = { class: "DailyConsumption" }
const _hoisted_24 = { class: "BarLineGraphic" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "BarLineGraphic__filters" }
const _hoisted_27 = { class: "BarLineGraphic__desktop" }

import PageConso from "@/components/ui/organisms/layouts/MyVPageConso/MyVPageConso.vue";
import BarChart from "@/components/ui/atoms/graphics/MyVBarGraphic/MyVBarGraphic.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import BarRangeColorGraphic from "@/components/ui/atoms/graphics/MyVBarRangeColorGraphic/MyVBarRangeColorGraphic.vue";
import MyVAntdswitch from "@/components/ui/atoms/MyVAntdswitch/MyVAntdswitch.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import {AuthModule, ConsoModule, ContractModule, SiteModule, SwitchConsoModule} from "@/store";
import {
  aggregatedDayLiveFormat,
  EAggregationAlias,
  hourLiveFormat,
  liveMinuteMode,
  liveSecondMode,
  TConsoAggregated,
  TConsumption
} from "@/services/Conso/interfaces";
import ERoutes from "@/router/routes";
import {TBarRangeColorGraphic} from "@/components/ui/atoms/graphics/MyVBarRangeColorGraphic/interfaces";
import {Dropdown, Menu, Spin} from "ant-design-vue";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import {digitalFormat} from "@/helpers/number/formatNumber";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {TSite} from "@/services/Site/interfaces";
import {getContractFromDate} from "@/helpers/domains/conso";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import MyVDailyConsumption from "@/components/domains/Conso/MyVDailyConsumption/MyVDailyConsumption.vue";
import {ETemperatureUnit} from "@/components/domains/Heating/MyVTemperature/interfaces";
import {TUser} from "@/services/Auth/interfaces";
import {useResponsive} from "@/composables/useResponsive";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {useDate} from "@/helpers/dates/date-utils";
import useCurrency from "@/composables/currency";


export default /*@__PURE__*/_defineComponent({
  __name: 'LiveView',
  setup(__props) {

const showHistoric = ref(false);
const instantConsumption = ref(0);
const todayConso = ref(0);
const todayConsoCurr = ref(0);
const isStepMinutes = ref(true);
const hasLoadTen = ref(false);
const consoTypeKey = ref(0);
const showConsoInfoPopin = ref(false);
const aggregatedConsumption = ref<TConsumption[] | null>(null);
const poll = ref<any | null>(null);

const {t} = useI18n()
const {currency} = useCurrency()
const route = useRoute()
const router = useRouter()
const {isMobile} = useResponsive()

const links = computed(() => {
  return [
    {
      value: ERoutes.CONSO_LIVE,
      label: t('conso.view.live'),
      url: `${ERoutes.CONSO}/live`,
    },
    {
      value: ERoutes.CONSO_WEEK,
      label: t('conso.view.weekly'),
      url: {path: ERoutes.CONSO, query: {type: 'week'}},
    },
    {
      value: ERoutes.CONSO_MONTH,
      label: t('conso.view.monthly'),
      url: {path: ERoutes.CONSO, query: {type: 'month'}}
    },
    {
      value: ERoutes.CONSO_YEAR,
      label: t('conso.view.annual'),
      url: {path: ERoutes.CONSO, query: {type: 'year'}},
    },
  ];
})

const currentLink = computed(() => {
  const name = route.name;
  const type = route.query.type;

  if (name === ERoutes.CONSO_LIVE) {
    return t('conso.view.live');
  } else if (name === ERoutes.CONSO_WEEK || type === ERoutes.CONSO_WEEK) {
    return t('conso.view.weekly');
  } else if (name === ERoutes.CONSO_YEAR || type === ERoutes.CONSO_YEAR) {
    return t('conso.view.annual');
  }

  return t('conso.view.monthly');
})

const isOffPeakContract = computed(() => {
  return contract.value && !!contract.value?.offpeakHours.length;
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const generalMeasureText = computed(() => {
  if (!AuthModule.userOrNull) return null;

  return currentSite.value.hasGlobalConsumptionMeasure ? t('conso.live.housing') : t('conso.live.connected_devices');
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const contract = computed(() => {
  return getContractFromDate(null);
})

const barLineMonthConso = computed(() => {
  const consu = aggregatedConsumption.value!;
  if (!consu) {
    return null;
  }

  const hourRange: any[] = [];
  const labels: TBarRangeColorGraphic['labels'] = [];
  const ranges: TBarRangeColorGraphic['ranges'] = hourRange

  const peakDatas: TBarRangeColorGraphic['datas'] = []
  const offpeakDatas: TBarRangeColorGraphic['datas'] = []
  let start = undefined
  const startOfDay = useDate().localTimezoneDate().startOf('day');

  consu.forEach(((el, idx) => {
    if (idx === 0)
      start = useDate(el.stepTimestampOnSite!).format('H')

    peakDatas.push(
        consoType.value
            ? el.peakHourConsumptionInWh ? fromWhToKwh(el.peakHourConsumptionInWh!) : null
            : el.peakHourConsumptionInCurrency! ? el.peakHourConsumptionInCurrency! : null
    );

    if (el.offPeakHourConsumptionInWh)
      offpeakDatas.push(
          consoType.value
              ? el.offPeakHourConsumptionInWh ? fromWhToKwh(el.offPeakHourConsumptionInWh!) : null
              : el.offPeakHourConsumptionInCurrency! ? el.offPeakHourConsumptionInCurrency! : null)
    const formattedHour = startOfDay.add(idx, 'hour').format(hourLiveFormat) + 'h';

    labels.push(formattedHour);
  }))

  if (offpeakDatas.length > 0) {
    hourRange.push({
      start: start,
      color: "rgb(151, 190, 13)",
      title: t('conso.live.title.offpeak_hour')
    })
  }

  if (peakDatas.length > 0) {
    hourRange.push({
      start: start,
      color: "rgb(77, 190, 247)",
      title: isOffPeakContract.value ? t('conso.live.title.peak_hour') : t('conso.chart.consumption')
    })
  }
  return {
    labels,
    peakDatas,
    offpeakDatas,
    ranges,
    columnBackgroundColor: "rgb(228, 232, 239)",
    leftAxis: {
      min: 0,
      title: consoType.value ? "kWh" : currency.value,
      step: consoType.value ? 0.5 : 0.1
    },
    rightAxis: {
      min: -10,
      max: 35,
      title: ETemperatureUnit.Celsius,
    },
  };
})

const consoType = computed((): boolean => {
  return SwitchConsoModule.switchType
})

const realTimeConso = computed((): TConsoAggregated | undefined => {
  return ConsoModule.realTimeConso;
})

const user = computed((): TUser => {
  return AuthModule.user;
})

const realTimeChartData = computed(() => {
  if (!realTimeConso.value) {
    return null
  }

  const consumptions = realTimeConso.value!.consumptions
  const labels: string[] = []
  const datas: number[] = []

  if (consumptions.length) {
    consumptions.forEach(consumption => {
      if (consumption) {
        labels.push(useDate(consumption.stepTimestampInUtc).localTimezoneDate().format('HH:mm:ss'))
        datas.push(consumption.totalConsumptionInWh)
      }
    })
  }

  return {
    labels,
    datas,
    minBarLength: 10,
    color: {
      red: 173,
      green: 204,
      blue: 64,
    },
    leftAxis: {
      min: 0,
      step: 100,
      title: 'W'
    },
  }
})

const openConsoInfoPopin = () => {
  showConsoInfoPopin.value = true;
  pageScroll(false);
}

const closeWelcomeConsoPopin = () => {
  showConsoInfoPopin.value = false;
  pageScroll(true);
}

const loadData = (isLoadData = true) => {
  if (isLoadData) loadRealTimeData(getCurrentSiteIdFromCookie(user.value), hasLoadTen.value ? 1 : 10);
  poll.value = setTimeout(loadData, isStepMinutes.value ? 600000 : 10000);
  hasLoadTen.value = true;
};

const loadRealTimeData = (siteId: number, numPoints = 1) => {
  ConsoModule.getRealTimeConso({
    siteId,
    mode: isStepMinutes.value ? liveMinuteMode : liveSecondMode,
    numPoints
  }).then((data) => {
    instantConsumption.value = Math.round(data!.consumptions[data!.consumptions.length - 1] ? data!.consumptions[data!.consumptions.length - 1].totalConsumptionInWh : 0);
  })
}

const consoDetailRoute = (to: string) => {
  const todayDate = useDate().format(aggregatedDayLiveFormat);

  return {
    path: `${ERoutes.CONSO}/${to}/${todayDate}`,
    query: {
      type: 'day',
    }
  }
}

const handleDetailsClick = () => {
  if (isMobile.value)
    router.push(consoDetailRoute(ERoutes.CONSO_DETAIL))
}

const loadConsoAggregated = () => {
  ConsoModule.getConsumptionFullData({
    period: EAggregationAlias.Day,
    dates: useDate().format(aggregatedDayLiveFormat),
    forceRequest: true
  })
      .then(data => {
        data!.dataPoints?.forEach(el => {
          todayConso.value += el.totalConsumptionInWh
          todayConsoCurr.value += el.totalConsumptionInCurrency ?? 0
        })
        todayConso.value = digitalFormat(fromWhToKwh(todayConso.value), 2)
        aggregatedConsumption.value = data!.dataPoints ?? []
      });
}

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
        }
      })
  consoTypeKey.value++
}

onBeforeMount(() => {
  ContractModule.getContractsList();
  loadConsoAggregated();
  SiteModule.getSites()

  const loadData = () => {
    loadRealTimeData(getCurrentSiteIdFromCookie(user.value), hasLoadTen.value ? 1 : 10);
    poll.value = setTimeout(loadData, isStepMinutes.value ? 600000 : 10000);
    hasLoadTen.value = true;
  };
  loadData();
})

onBeforeUnmount(() => {
  clearInterval(poll.value);
  ConsoModule._resetRealTimeConso();
})

watch(isStepMinutes, () => {
  hasLoadTen.value = false;
  clearInterval(poll.value);
  ConsoModule._resetRealTimeConso();
  loadData();
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PageConso, {
      title: _ctx.$t('conso.live.page.title')
    }, {
      headerDesktop: _withCtx(() => [
        _createVNode(MyVHeaderMainPage, {
          title: "conso.live.page.title",
          class: "HeaderMainPage--md-small"
        }, {
          HeaderMainPage__right: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(Dropdown), {
                class: "__view",
                trigger: ['click']
              }, {
                overlay: _withCtx(() => [
                  _createVNode(_unref(Menu), { class: "menu" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link, index) => {
                        return (_openBlock(), _createBlock(_component_router_link, {
                          key: index,
                          to: link.url,
                          "active-class": "active",
                          class: "item"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(link.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(MyVButton, {
                    icon: "triangle",
                    primary: false,
                    class: "PageConso__headerButton",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(currentLink.value), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(instantConsumption.value), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(EEnergyUnit).W), 1)
                ]),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('conso.live.instant_consumption')), 1)
              ]),
              _createElementVNode("div", _hoisted_11, _toDisplayString(isStepMinutes.value ? _ctx.$t('live.step.info.minutes') : _ctx.$t('live.step.info.seconds')), 1),
              (realTimeChartData.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(BarChart, _normalizeProps(_guardReactiveProps(realTimeChartData.value)), null, 16)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_unref(Spin), {
                      size: "large",
                      tip: _ctx.$t('live.step.loading')
                    }, null, 8, ["tip"])
                  ])),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(MyVButton, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (isStepMinutes.value = !isStepMinutes.value))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(isStepMinutes.value ? _ctx.$t('live.step.ten.seconds') : _ctx.$t('live.step.ten.minutes')), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('conso.live.instant_consumption')), 1),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(generalMeasureText.value), 1),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(instantConsumption.value), 1),
                    _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(EEnergyUnit).W), 1)
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createVNode(MyVDailyConsumption, {
                value: todayConso.value,
                currencyValue: todayConsoCurr.value,
                "show-currency-equivalent": ""
              }, null, 8, ["value", "currencyValue"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              (!showHistoric.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", {
                          class: "BarLineGraphic__title",
                          onClick: openConsoInfoPopin
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('conso.live.today_consumption')), 1),
                          _cache[3] || (_cache[3] = _createElementVNode("svg", {
                            class: "icon icon-question-circle",
                            "aria-hidden": "true"
                          }, [
                            _createElementVNode("use", { "xlink:href": "#icon-question-circle" })
                          ], -1))
                        ]),
                        _createVNode(_component_router_link, {
                          class: "BarLineGraphic__link",
                          to: consoDetailRoute(_unref(ERoutes).CONSO_DETAIL)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('conso.page.button.detail')), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _createVNode(MyVDisplayGroupStore, {
                        currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(MyVAntdswitch, {
                            defaultValue: consoType.value,
                            onChange: setConsoType,
                            checkedChildren: _unref(currency),
                            size: "small",
                            unCheckedChildren: _unref(EEnergyUnit).kWh,
                            value: consoType.value,
                            key: consoTypeKey.value
                          }, null, 8, ["defaultValue", "checkedChildren", "unCheckedChildren", "value"]))
                        ]),
                        _: 1
                      }, 8, ["currentRight"])
                    ]),
                    _createElementVNode("div", {
                      class: "BarLineGraphic__content",
                      onClick: handleDetailsClick
                    }, [
                      (barLineMonthConso.value)
                        ? (_openBlock(), _createBlock(BarRangeColorGraphic, _normalizeProps(_mergeProps({ key: 0 }, barLineMonthConso.value)), null, 16))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_router_link, {
                      class: "BarLineGraphic__link BarLineGraphic__link__mobile",
                      to: consoDetailRoute(_unref(ERoutes).CONSO_DETAIL)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('conso.page.button.detail')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    (showConsoInfoPopin.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ?  MyVBottomPopin : MyVPageWithReturn), {
          key: 0,
          title: _ctx.$t('conso.tooltip.title'),
          class: "default-contract-popin",
          onClose: closeWelcomeConsoPopin,
          type: "popin",
          onCancel: _cache[2] || (_cache[2] = () => showConsoInfoPopin.value = false),
          "return-action": () =>showConsoInfoPopin.value = false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(currentSite.value.hasGlobalConsumptionMeasure ? _ctx.$t('conso.tooltip.general_consumption_by_voltalis') : _ctx.$t('conso.tooltip.devices_measure')), 1)
          ]),
          _: 1
        }, 40, ["title", "return-action"]))
      : _createCommentVNode("", true)
  ]))
}
}

})