import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MenuSlider__container" }
const _hoisted_2 = { class: "MenuSlider__item-text" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 0,
  class: "MenuSlider__item-counter"
}
const _hoisted_6 = {
  key: 0,
  class: "MenuSlider__item-counterIcon warning"
}
const _hoisted_7 = {
  key: 1,
  class: "MenuSlider__item-counterIcon rond-yellow"
}
const _hoisted_8 = {
  key: 2,
  class: "MenuSlider__item-counterIcon rond-violet"
}
const _hoisted_9 = { class: "MenuSlider__item-count" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

import {TMenuSlider, TMenuSliderOption} from "./interfaces";
import {getCurrentInstance, nextTick, onBeforeMount, onMounted, reactive, ref, watch} from "vue";

interface Props {
  defaultCurrent?: TMenuSlider["defaultCurrent"];
  options?: TMenuSlider["options"];
  isBlue?: TMenuSlider["isBlue"];
  onCurrentValueChange?: TMenuSlider["onCurrentValueChange"];
  routeFunc?: TMenuSlider["routeFunc"];
  itemType?: TMenuSlider['itemType']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVMenuSlider',
  props: {
    defaultCurrent: { default: '' },
    options: { default: () => [] },
    isBlue: { type: Boolean, default: false },
    onCurrentValueChange: { type: Function, default: undefined },
    routeFunc: { type: Function, default: undefined },
    itemType: { default: undefined }
  },
  emits: ['change'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const currentValue = ref<TMenuSliderOption['value']>('');
const optionsRef = ref<any>({});
const MenuSlider = ref(null)
const instance = getCurrentInstance();
const allowScrollMouseMove = ref(false);
const allowLinkClick = ref(true);
const pos = reactive({x: 0, y: 0});
const emit = __emit
const formatedLabel = (label: string) => {
  let formatedLabel = label!;
  if (formatedLabel && label!.length > 25) {
    formatedLabel = formatedLabel.slice(0, 25) + "...";
  }
  return formatedLabel;
}

const setRefOption = (ref: any) => {
  if (ref) {
    const el = ref.$el || ref;

    if (el) {
      optionsRef.value[el.dataset.value] = el;
    }
  }
}

const handleClick = (value: string) => {
  currentValue.value = value;
  instance?.proxy?.$forceUpdate();
  scrollToCurrent();
}

const scrollToCurrent = () => {
  const $MenuSlider: any = MenuSlider.value;
  const $current: any = optionsRef.value[currentValue.value];

  if ($MenuSlider && $current) {
    const offsetCurrent = ($current.offsetLeft - $MenuSlider.offsetLeft);
    const centerSlider = $MenuSlider.offsetWidth / 2;
    const currentWidth = $current.offsetWidth / 2;

    $MenuSlider.scrollLeft = offsetCurrent - centerSlider + currentWidth;
  }
}

const handleMouseDown = (e: any) => {
  allowScrollMouseMove.value = true;
  pos.x = e.clientX
  pos.y = e.clientY
}

const handleMouseMove = (e: any) => {
  e.preventDefault()
  if (allowScrollMouseMove.value) {
    const $MenuSlider: any = MenuSlider.value;
    if ($MenuSlider) {
      const dx = e.clientX - pos.x;
      $MenuSlider.scrollLeft = $MenuSlider.scrollLeft - dx;
      pos.x = e.clientX;
    }
  }
}

const handleMouseLeave = () => {
  allowScrollMouseMove.value = false;
  allowLinkClick.value = true
}
const handleMouseUp = () => {
  allowScrollMouseMove.value = false;
  setTimeout(() => {
    allowLinkClick.value = true
  }, 50)
}

onBeforeMount(() => {
  if (props.defaultCurrent) {
    for (let i = 0, len = props.options.length; i < len; i++) {
      if (props.options[i].value === props.defaultCurrent) {
        currentValue.value = props.defaultCurrent;
        break;
      }
    }
  } else if (props.options && props.options[0]) {
    currentValue.value = props.options[0].value;
  }

})

const url = (value: TMenuSliderOption['value']) => {
  if (props.routeFunc) {
    return props.routeFunc?.(value);
  } else {
    return "#" + value;
  }
}

onMounted(() => {
  nextTick(scrollToCurrent)
})

watch(() => props.defaultCurrent, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    currentValue.value = newValue
    nextTick(scrollToCurrent)
  }
})

watch(currentValue, (newValue) => {
  props.onCurrentValueChange?.(newValue);
  currentValue.value = newValue;
  emit('change', newValue)
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['MenuSlider', {'MenuSlider--blue': _ctx.isBlue}, {'MenuSlider--drag': allowScrollMouseMove.value}]),
    ref_key: "MenuSlider",
    ref: MenuSlider,
    onMousedown: handleMouseDown,
    onMousemove: handleMouseMove,
    onMouseup: handleMouseUp,
    onMouseleave: handleMouseLeave
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent((_ctx.itemType === 'link' && allowLinkClick.value) ? 'router-link' : 'div'), {
          to: url(option.value),
          class: _normalizeClass(['MenuSlider__item', {'active':(currentValue.value === option.value)}]),
          key: option.value,
          "data-value": option.value,
          ref_for: true,
          ref: setRefOption,
          onClick: _withModifiers(($event: any) => (handleClick(option.value, $event)), ["prevent"])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              (option.labelNotActive && currentValue.value != option.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(option.labelNotActive), 1))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    title: option.label
                  }, _toDisplayString(formatedLabel(option.label)), 9, _hoisted_4))
            ]),
            _cache[1] || (_cache[1] = _createTextVNode("   ")),
            (option.counter)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  (option.counter.type === 'warning')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[0] || (_cache[0] = [
                        _createElementVNode("svg", {
                          class: "icon icon-warning",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-warning" })
                        ], -1)
                      ])))
                    : _createCommentVNode("", true),
                  (option.counter.type === 'rond-yellow')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                    : _createCommentVNode("", true),
                  (option.counter.type === 'rond-violet')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_9, [
                    (option.counter.type != 'warning' && option.counter.type != 'rond-yellow' && option.counter.type != 'rond-violet')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, "(" + _toDisplayString(option.counter.count) + ")", 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(option.counter.count), 1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to", "class", "data-value", "onClick"]))
      }), 128))
    ])
  ], 34))
}
}

})