import {TText} from '@/graphs/interfaces';
import {computed} from 'vue';
import {SiteModule} from '@/store';
import {hasFractionalDigits} from '@/helpers/number/formatNumber';

export enum ECurrency {
    EUR = "€",
    GBP = "£"
}

export enum ECountry {
    FRANCE = "France",
    UK = "Royaume-Uni"
}

enum EIsoCodeCountry {
    FRANCE = "fr-FR",
    UK = "en-GB"
}

enum EIsoCodeCurrency {
    FRANCE = "EUR",
    UK = "GBP",
}

const currencyFromCountry: Record<ECountry, ECurrency> = {
    [ECountry.FRANCE]: ECurrency.EUR,
    [ECountry.UK]: ECurrency.GBP,
}

const isoCodeCountryFromCountry: Record<ECountry, EIsoCodeCountry> = {
    [ECountry.FRANCE]: EIsoCodeCountry.FRANCE,
    [ECountry.UK]: EIsoCodeCountry.UK,
}

const isoCodeCurrencyFromCountry: Record<ECountry, EIsoCodeCurrency> = {
    [ECountry.FRANCE]: EIsoCodeCurrency.FRANCE,
    [ECountry.UK]: EIsoCodeCurrency.UK,
}

const maximumFractionDigits: Record<ECurrency, number> = {
    [ECurrency.EUR]: 2,
    [ECurrency.GBP]: 2
}

export default function useCurrency() {
    const siteCountry = computed<ECountry>(() => {
        //defensive behaviour, if we do not have the country, we assume that it might be certainly French
        return SiteModule.siteCountry ? SiteModule.siteCountry : ECountry.FRANCE
    })

    const currenciesBeforeText = [ECurrency.GBP]


    const currency = computed<ECurrency>(() => currencyFromCountry[siteCountry.value])

    const isCurrencyBeforeText = computed(() => currenciesBeforeText.includes(currency.value))

    function formatCurrency(value: number, fractionDigits: number = maximumFractionDigits[currency.value]
    ): string {
        const isoCodeCountry: EIsoCodeCountry = isoCodeCountryFromCountry[siteCountry.value]
        const isoCodeCurrency: EIsoCodeCurrency = isoCodeCurrencyFromCountry[siteCountry.value]

        return Intl
            .NumberFormat(isoCodeCountry, {
                style: "currency",
                currency: isoCodeCurrency,
                maximumFractionDigits: hasFractionalDigits(value) ? fractionDigits : 0,
            })
            .format(value)
    }

    function isCurrency(value: string): boolean {
        return value === ECurrency.EUR || value === ECurrency.GBP
    }

    function formatTextUnitArray(array: TText[]) {
        if (isCurrencyBeforeText.value && array.length === 2) {
            return [array[0], array[1]] = [array[1], array[0]]
        }
        return array
    }

    return {currency, formatCurrency, isCurrency, formatTextUnitArray, isCurrencyBeforeText}
}
