import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "PageMain" }
const _hoisted_2 = {
  class: "PageMain__header",
  "data-testid": "navigation-bar"
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 1,
  class: "PageMain__header-title"
}
const _hoisted_5 = { class: "PageMain__mainNav" }
const _hoisted_6 = { class: "PageMain__mainNavWrapper" }
const _hoisted_7 = { class: "PageMain__secondaryNav" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "PageMain__notifTag"
}
const _hoisted_10 = {
  key: 0,
  class: "PageMain__profilNotif"
}

import {TPageMain} from "./interfaces";
import {TabNavFakeDatas} from "@/dummies/TabNavFakeDatas";
import ProfileMenu from "@/components/ui/organisms/MyVProfileMenu/MyVProfileMenu.vue";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import MyVNotification from "@/components/ui/organisms/MyVNotification/MyVNotification.vue";

import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {AuthModule, ContractModule, NotificationModule, SiteModule, ThemeModule} from "@/store";
import Cookie from "@/helpers/cookie";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {computed, onBeforeMount, ref} from "vue";
import {useI18n} from "vue-i18n";
import MyVTabNavStore from "@/components/ui/molecules/MyVTabNav/MyVTabNavStore.vue";
import {useCookieNames} from "@/composables/useCookieNames";

export interface Props {
  title?: TPageMain["title"];
  homeLink?: TPageMain["homeLink"];
  logo?: TPageMain["logo"];
  mainNav?: any;
  secondaryNav?: any;
  hasProgramBar?: TPageMain["hasProgramBar"];
  hasProgramListBar?: TPageMain["hasProgramListBar"];
  hasFixedButtonBottom?: TPageMain["hasFixedButtonBottom"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPageMain',
  props: {
    title: { default: '' },
    homeLink: { default: '' },
    logo: { default: '' },
    mainNav: { default: undefined },
    secondaryNav: { default: '' },
    hasProgramBar: { type: Boolean, default: false },
    hasProgramListBar: { type: Boolean, default: false },
    hasFixedButtonBottom: { type: Boolean, default: false }
  },
  setup(__props: any) {



const showProfilMenu = ref(false);
const showNotification = ref(false);

const {t} = useI18n()

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)
const {contractNotificationCookie} = useCookieNames()

const listItem = computed(() => {
  return TabNavFakeDatas.map(n => ({...n, title: t(n.title)}));
})

const showContractNotif = computed(() => {
  const cookie = Cookie.get(contractNotificationCookie.value);

  return !(cookie || ContractModule.isPdlChecked || ContractModule.isPdlValid);
})

const theme = computed(() => {
  return ThemeModule.theme
})

const notificationsNumber = computed(() => {
  let unseenNotifsCounter = 0;
  NotificationModule.notifications?.forEach(notifications => {
    unseenNotifsCounter += notifications.notifications.filter(notif => !notif.seen).length
  })

  return unseenNotifsCounter;
})

const openProfilMenu = (e: any) => {
  e.stopPropagation();
  showProfilMenu.value = true;
  pageScroll(false)
}

const closeProfilMenu = () => {
  showProfilMenu.value = false;
  pageScroll(true);
}

const stopPropagation = (e: any) => {
  e.stopPropagation();
}

const openNotification = () => {
  pageScroll(false);
  showNotification.value = true;
}

const closeNotification = () => {
  pageScroll(true);
  showNotification.value = false;
}

onBeforeMount(() => {
  document.addEventListener('closeProfilMenu', closeProfilMenu);
  NotificationModule.getNotifications({
    siteId: getCurrentSiteIdFromCookie(AuthModule.user),
    skipLoading: true
  });
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.logo)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.homeLink,
            class: "PageMain__logo"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: theme.value.staticResources.logo,
                alt: _ctx.title,
                class: "PageMain__logo-img"
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.title), 1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(MyVTabNavStore, { listItem: listItem.value }, null, 8, ["listItem"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(MyVDisplayGroupStore, {
          currentRight: _unref(EDisplayGroupRightName).NOTIFICATION
        }, {
          default: _withCtx(({disabled}) => [
            _createElementVNode("button", {
              type: "button",
              class: _normalizeClass({ 'PageMain__profilTrigger': true, 'disabled': disabled }),
              disabled: disabled,
              onClick: openNotification
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("svg", {
                class: "icon icon-bell",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-bell" })
              ], -1)),
              (notificationsNumber.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(notificationsNumber.value > 99 ? 99 : notificationsNumber.value), 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_8)
          ]),
          _: 1
        }, 8, ["currentRight"]),
        _createElementVNode("div", {
          class: "PageMain__profilTrigger",
          onClick: openProfilMenu
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("svg", {
            class: "icon icon-profil",
            "aria-hidden": "true"
          }, [
            _createElementVNode("use", { "xlink:href": "#icon-profil" })
          ], -1)),
          (showContractNotif.value && isSiteCountryFrance.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("main", {
      class: _normalizeClass(['PageMain__main', {'hasProgramBar':_ctx.hasProgramBar}, {'hasProgramListBar':_ctx.hasProgramListBar} ])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (showProfilMenu.value)
      ? (_openBlock(), _createBlock(ProfileMenu, {
          key: 0,
          isOverlay: true,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (showProfilMenu.value = false)),
          onClick: stopPropagation
        }))
      : _createCommentVNode("", true),
    (showNotification.value)
      ? (_openBlock(), _createBlock(MyVNotification, {
          key: 1,
          onClose: closeNotification
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})