import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {message} from 'ant-design-vue';

import {
    TChangePasswordParams,
    TCheckTokenValidityParams,
    TCreateOnboardingPasswordParams,
    TEditUserParams,
    TNewPasswordResponse,
    TNewPasswordUpdateParams,
    TRequestNewPasswordParams,
    TUser
} from "@/services/Auth/interfaces";
import AuthService from "@/services/Auth";

import {EModules} from "../modules";
import {resetStore} from "..";

import Cookie from "@/helpers/cookie";
import {TSite} from "@/services/Site/interfaces";
import {DISPLAY_GROUP_BETA_TESTER, DISPLAY_GROUP_EKW} from "@/services/Theme/interfaces";
import {setToken} from "@/helpers/domains/flutter-connectors";
import * as Sentry from "@sentry/vue";
import {useDate} from "@/helpers/dates/date-utils";
import {ECookieNames} from "@/helpers/cookie/interfaces";

type TLoginParams = {
    username: string;
    password: string;
    onBeforeLog?: (user: TUser) => Promise<any>
};

type TSetCurrentSiteId = {
    user: TUser,
    siteId: TSite['id']
}

let g_isConnecting: Promise<TUser | null> | null | false = false;

@Module({name: EModules.AUTH})
class Auth extends VuexModule {
    private _user: TUser | null = null;

    /**
     * Getters
     */

    get userOrNull(): TUser | null {
        return this._user;
    }

    /**
     * Getter to get user if you are sure that user is set. It's most of the case true on logged route.
     */
    get user(): TUser {
        return this._user!;
    }

    get isEkwateur(): boolean {
        return this._user?.displayGroup?.name === DISPLAY_GROUP_EKW;
    }

    get isBetaTester() {
        return this._user?.displayGroup.name === DISPLAY_GROUP_BETA_TESTER;
    }

    get hasDsoMeasure():boolean | undefined{
      return this._user?.defaultSite.hasDsoMeasure
    }

    /**
     * Mutations
     */

    @Mutation
    public _setSite(site: TSite): void {
        this._user!.defaultSite = site;
    }

    @Mutation
    public _setUser(user: TUser): void {
        this._user = user;
        Sentry.setUser({email: user.email, id: user.id, username: `${user.firstname} ${user.lastname}`});
    }

    @Mutation
    public _editUser(user: Partial<TUser>): void {
        this._user = {
            ...this._user!,
            ...user,
        };
    }

    @Mutation
    public _logout(): void {
        resetStore();
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public login({username, password, onBeforeLog}: TLoginParams): Promise<TUser | void> {
        return AuthService.login(username, password)
            .then((user) => {
                if (user) {
                    this.manageEnedisCounterCookie(user);
                    this.manageEnedisExpirationDateCookie(user);
                    this.manageCurrentSiteId({
                        user,
                        siteId: user.defaultSite.id
                    });
                }
                return Promise.all([user, onBeforeLog?.(user!)])
            })
            .then(([user]) => {
                this.context.commit("_setUser", user as TUser);
                return user;
            });
    }

    @Action({rawError: true})
    public tryConnectUserFromCookie(): Promise<TUser | null> {

        if (!g_isConnecting) {
            const token = Cookie.get(ECookieNames.TOKEN);

            g_isConnecting = this.getUserFromToken(token)
                .then(async user => {
                    await setToken(token, user?.defaultSite.id)
                    g_isConnecting = null;
                    return user;
                });
        }

        return g_isConnecting;
    }

    @Action({rawError: true})
    public tryConnectUserFromToken(token: string): Promise<TUser | null> {

        if (!g_isConnecting) {
            g_isConnecting = this.getUserFromToken(token)
                .then(async user => {
                    g_isConnecting = null;
                    await setToken(token, user?.defaultSite.id)
                    return user;
                });
        }
        return g_isConnecting;
    }

    @Action({rawError: true})
    public checkConnectUserState(): Promise<TUser | null | false> {
        if (g_isConnecting === false) {
            return Promise.resolve(false);
        }
        return g_isConnecting ?? Promise.reject();
    }

    @Action({rawError: true})
    public editUser(body: TEditUserParams): Promise<TUser | void> {
        return AuthService.editUser(body).then((user) => {
            this.context.commit("_editUser", user);
            return user
        }).catch(({response}: any) => {
            switch (response.status) {
                case 403:
                    message.error('Forbiden !');
                    break;
                default:
                    message.error('An error occurs !');
                    break;
            }
        })
    }

    @Action({rawError: true})
    public changePassword(body: TChangePasswordParams): Promise<void> {
        return AuthService.changePassword(body);
    }

    @Action({rawError: true})
    public requestNewPassword(body: TRequestNewPasswordParams): Promise<void> {
        return AuthService.requestNewPassword(body);
    }

    @Action({rawError: true})
    public changeDefaultSite(site: TSite): void {
        this.context.commit("_setSite", site);
    }

    @Action({rawError: true})
    public checkOnboardingTokenValidity(body: TCheckTokenValidityParams): Promise<TNewPasswordResponse> {
        return AuthService.checkOnboardingTokenValidity(body);
    }

    @Action({rawError: true})
    public createOnboardingPassword(body: TCreateOnboardingPasswordParams): Promise<TNewPasswordResponse> {
        return AuthService.createOnboardingPassword(body);
    }

    @Action({rawError: true})
    public checkNewPasswordTokenValidity(body: TCheckTokenValidityParams): Promise<TNewPasswordResponse> {
        return AuthService.checkNewPasswordTokenValidity(body);
    }

    @Action({rawError: true})
    public newPasswordUpdate(body: TNewPasswordUpdateParams): Promise<TNewPasswordResponse> {
        return AuthService.newPasswordUpdate(body)
            .then((data) => data)
            .catch(e => {
                if (e.response && e.response.status == 301) {
                    window.location.href = e.response.data.oldMyVoltalisUrl
                }
                throw e;
            })
    }


    @Action({rawError: true})
    public logout(): Promise<void> {
        return AuthService.logout().then(() => {
            this.context.commit("_logout")
            Cookie.resetLocaleCookies()
        });
    }

    @Action({rawError: true})
    public getUserFromToken(token: string | null): Promise<TUser | null> {
        return (token ? (
            AuthService.loginFromCookie(token)
                .then((user) => {
                    this.context.commit("_setUser", user);
                    return user;
                })
                .catch(() => {
                    return null;
                })
        ) : Promise.resolve(null));
    }

    @Action({rawError: true})
    public manageEnedisExpirationDateCookie(user: TUser) {
        const cookie = Cookie.get(`${ECookieNames.ENEDIS_DATE}${user.id}`);
        const expirationDateInMilliseconds = useDate().add(3, 'days').format("YYYY-MM-DD[T]HH:mm:ss");

        if (!cookie) {
            /**
             * Set date expiration to 3 days based of milliseconds
             */
            Cookie.set(`${ECookieNames.ENEDIS_DATE}${user.id}`, 'true', {
                expires: expirationDateInMilliseconds
            });
        } else {
            Cookie.set(`${ECookieNames.ENEDIS_DATE}${user.id}`, 'false', {
                expires: expirationDateInMilliseconds
            });
        }
    }

    @Action({rawError: true})
    public manageEnedisCounterCookie(user: TUser) {
        const cookieName = `${ECookieNames.ENEDIS_COUNTER}${user.id}`;
        const cookie = Cookie.get(cookieName);

        if (!cookie) {
            Cookie.set(cookieName, "1");
        } else {
            const newCounter = parseInt(cookie) + 1;
            Cookie.set(cookieName, newCounter.toString());
        }
    }

    @Action({rawError: true})
    public manageCurrentSiteId({user, siteId}: TSetCurrentSiteId) {
        const cookieName = `${ECookieNames.CURRENT_SITE_ID}${user.id}`;
        Cookie.set(cookieName, siteId.toString());
    }
}

export default Auth;
